
import RavenDataStore from "../../../components/common/ravenDataStore";

const ACCOUNTS_FROM_DATASTORE = {};

function includes(account, keyword){
    const emailIncludesKeyword = account.email && account.email.toLowerCase().includes(keyword);
    const accountExternalIdIncludesKeyword = account.accountExternalId && account.accountExternalId.toLowerCase().includes(keyword);

    return emailIncludesKeyword || accountExternalIdIncludesKeyword;
}

export default {
    selectors: {
        getAccounts: async (stage, keyword) => {
            if(!ACCOUNTS_FROM_DATASTORE[stage]){
                ACCOUNTS_FROM_DATASTORE[stage] = [];
            }

            if(ACCOUNTS_FROM_DATASTORE[stage].length === 0){
                const dataStore = new RavenDataStore((data) => {
                    ACCOUNTS_FROM_DATASTORE[stage].push(...data.accounts);
                });

                await dataStore.queryAccounts(stage);
            }

            return ACCOUNTS_FROM_DATASTORE[stage]
            .filter(item => 
                includes(item.account, keyword.toLowerCase()))
            .map(item => item.account);
        }
    }
}
