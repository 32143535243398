import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import globalconfig from '../../../../common/config';
import CognitoUtil from '../../../../aws/cognito/cognitoUtil';


const ACTION_STEP_STATUSES = {
    QUEUED: "QUEUED",           // ☐
    IN_PROGRESS: "IN_PROGRESS", // ◌
    COMPLETED: "COMPLETED",     // ☑
    ERROR: "ERROR",             // !
    TIMEOUT: "TIMEOUT"          // ◌ + error message
}

const ACTION_STEP_STATUS_ICON_IDS = { // https://fonts.google.com/icons
    QUEUED: "check_box_outline_blank",
    //IN_PROGRESS: "custom_activity_indicator",
    COMPLETED: "check_box",
    ERROR: "error",
    //TIMEOUT: "custom_activity_indicator",
}

const ACTION_REBOOT_RAVEN_STEP_IDS = {
    REQUEST_LOGS: "REQUEST_LOGS",
    POLLING_FOR_RECENT_LOGS: "POLLING_FOR_RECENT_LOGS",
    REQUEST_REBOOT: "REQUEST_REBOOT",
    POLLING_FOR_RECENT_REBOOT: "POLLING_FOR_RECENT_REBOOT"
}

const TOMBSTONE_MAX_AGE_SECONDS = 18000; // How many seconds from now before tombstones are considered stale (5 hours)
const TOMBSTONE_POLLING_INTERVAL_MILLISECONDS = 30000; // 30 seconds
const TOMBSTONE_POLLING_MAX_TIMEOUT_MILLISECONDS = 300000; // 5 minutes
const EVENT_RAVEN_REBOOT_MAX_AGE_SECONDS = 900; // How many seconds from now before a raven reboot is not associated with the reboot action request (15 mins)
const EVENT_RAVEN_REBOOT_POLLING_MAX_TIMEOUT_MILLISECONDS = 900000; // 15 minutes

export default class ActionsRebootRavenOverlay extends React.PureComponent {

    static propTypes = {
        dataStore: PropTypes.object.isRequired, // for requesting refresh for missing details on app header sort displayVariable changes
        raven: PropTypes.object.isRequired,
        feature: PropTypes.object.isRequired,
        oldestLoadedEventMoment: PropTypes.object, // moment.js
        mostRecentLoadedRavenBootedEventMoment: PropTypes.object, // moment.js
        stage: PropTypes.string.isRequired,
        reason: PropTypes.string.isRequired,
        onActionCancel: PropTypes.func.isRequired,
        onActionClose: PropTypes.func.isRequired,
        persistStateInLocalStorage: PropTypes.func.isRequired,
        restoredActionsOverlayState: PropTypes.object
    };

    initialState = { // used for resetting state
        cancelButtonIsDisabled: false,
        actionStartTime: new Date().getTime(),
        actionCompleted: false, // corresponds to POLLING_FOR_RECENT_REBOOT.status === COMPLETED
        actionErrorOccurred: false,
        actionStatusMessage: null, // displayed red if actionErrorOccurred
        actionSteps: { // only one action is supported for now:
            REQUEST_LOGS: { // ACTION_REBOOT_RAVEN_STEP_IDS.REQUEST_LOGS
                description: "Requesting logs",
                supplementaryDetail: null,
                status: ACTION_STEP_STATUSES.QUEUED,
                requestDatetime: null // Date.getTime() millisecond timestamp, tested against TOMBSTONE_POLLING_MAX_TIMEOUT_MILLISECONDS to set status TIMEOUT during POLLING_FOR_RECENT_LOGS
                //error: null
            },
            POLLING_FOR_RECENT_LOGS: { // ACTION_REBOOT_RAVEN_STEP_IDS.POLLING_FOR_RECENT_LOGS
                description: "Logs uploaded",
                supplementaryDetail: "(Checking every 30 seconds)",
                status: ACTION_STEP_STATUSES.QUEUED,
                //error: null
            },
            REQUEST_REBOOT: { // ACTION_REBOOT_RAVEN_STEP_IDS.REQUEST_REBOOT
                description: "Proceed with reboot",
                supplementaryDetail: null,
                status: ACTION_STEP_STATUSES.QUEUED,
                requestDatetime: null // Date.getTime() millisecond timestamp, tested against EVENT_RAVEN_REBOOT_POLLING_MAX_TIMEOUT_MILLISECONDS to set status TIMEOUT during POLLING_FOR_RECENT_REBOOT
                //error: null
            },
            POLLING_FOR_RECENT_REBOOT: { // ACTION_REBOOT_RAVEN_STEP_IDS.POLLING_FOR_RECENT_REBOOT
                description: "Raven rebooted",
                supplementaryDetail: "(Checking every 30 seconds)",
                status: ACTION_STEP_STATUSES.QUEUED,
                //error: null
            }
        },
        overridableTimeoutOccurred: false
    };
    constructor(props) {
        super(props);

        this.state = this.initialState;

        this.queryLogDataInterval = undefined;
        this.queryRavenEventsInterval = undefined;
    }

    onClose = () => {
        if (this.state.actionCompleted) {
            this.props.onActionClose();
        } else {
            this.props.onActionCancel();
        }
    }

    persistStateInLocalStorage = () => {
        this.props.persistStateInLocalStorage(this.state);
    }

    setRestoredActionsOverlayState = () => {

        if (this.props.restoredActionsOverlayState) {

            this.setState(this.props.restoredActionsOverlayState, () => {

                // Some cleanup for particular states to help resume gracefully, based on when requests were sent
                if (this.state.actionCompleted) {
                    // do nothing; (state does not need to be resumed)
                    return;
                }

                const now = new Date();

                const rebootRequestDatetime = this.state.actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.REQUEST_REBOOT].requestDatetime;

                const { actionSteps } = this.state;

            // CHECK FOR TIMEOUTS (roughly lines 355 - 409 below)

                // Since actionCompleted is false, and rebootRequestDatetime POLLING_FOR_RECENT_REBOOT must have been left in progress, see if it completed
                if (rebootRequestDatetime) {
                    if (this.props.mostRecentLoadedRavenBootedEventMoment) {

                        const lastRebootDate = this.props.mostRecentLoadedRavenBootedEventMoment.toDate();
    
                        if (lastRebootDate.getTime() > rebootRequestDatetime) {
    
                            clearInterval(this.queryRavenEventsInterval);
    
                            const mostRecentRebootTimestampDescription = this.props.mostRecentLoadedRavenBootedEventMoment.local().format(globalconfig.display.timeFormat) + " (" + this.props.mostRecentLoadedRavenBootedEventMoment.fromNow() + ")";
    
                            actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.POLLING_FOR_RECENT_REBOOT].status = ACTION_STEP_STATUSES.COMPLETED;
                            //actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.POLLING_FOR_RECENT_REBOOT].supplementaryDetail = ;
                            this.setState({
                                actionCompleted: true,
                                actionSteps: {...actionSteps},
                                actionStatusMessage: "Raven rebooted successfuly: " + mostRecentRebootTimestampDescription
                            }, this.persistStateInLocalStorage);
                            return;
                        }
                    }
                    // if currently more than EVENT_RAVEN_REBOOT_POLLING_MAX_TIMEOUT_MILLISECONDS (15 minutes) past the request time, reboot request timed out
                    if (now.getTime() - rebootRequestDatetime > EVENT_RAVEN_REBOOT_POLLING_MAX_TIMEOUT_MILLISECONDS) {

                        actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.POLLING_FOR_RECENT_REBOOT].status = ACTION_STEP_STATUSES.TIMEOUT;

                        this.setState({
                            actionSteps: {...actionSteps},
                            actionErrorOccurred: true,
                            actionStatusMessage: "Raven has not reported a new reboot event.  Click below to retry.",
                            overridableTimeoutOccurred: true // present a proceed anyway prompt to still allow trying to reboot raven
                        }, this.persistStateInLocalStorage);
                        return;
                    }

                    // otherwise, resuming polling for reboot event
                    clearInterval(this.queryRavenEventsInterval); // probably not necessary, but doing it anyway for due diligence
                    this.queryRavenEventsInterval = setInterval(this.refreshRavenEvents, TOMBSTONE_POLLING_INTERVAL_MILLISECONDS);
                    return;    
                }

                const actionStartTime = this.state.actionStartTime;
                if (now.getTime() - actionStartTime > EVENT_RAVEN_REBOOT_POLLING_MAX_TIMEOUT_MILLISECONDS) {

                    const revertedState = this.initialState;
                    revertedState.actionErrorOccurred = true;
                    revertedState.actionStatusMessage = "A previous request stalled waiting for logs. Click 'Cancel' and retry, or click below to try rebooting raven now.";
                    revertedState.overridableTimeoutOccurred = true;
                    this.setState({...revertedState}, this.persistStateInLocalStorage);

                    return;
                }


            // RESUME ACTIVITES BASED ON RESTORED STATE

                // check for what seems like an edge case (resuming a state that is in the middle of sending a reboot request that never completed (no rebootRequestDatetime, checked above))
                if ((this.state.actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.REQUEST_LOGS].status === ACTION_STEP_STATUSES.QUEUED) &&
                    (this.state.actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.POLLING_FOR_RECENT_LOGS].status === ACTION_STEP_STATUSES.COMPLETED)) {

                    this.requestRavenRebootAndStartPolling();
                    return;
                }

                if (this.state.actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.POLLING_FOR_RECENT_LOGS].status === ACTION_STEP_STATUSES.IN_PROGRESS) {
                    if (this.state.actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.REQUEST_LOGS].status === ACTION_STEP_STATUSES.COMPLETED) {
                        // make sure polling is active
                        clearInterval(this.queryLogDataInterval); // probably not necessary, but doing it anyway for due diligence
                        this.queryLogDataInterval = setInterval(this.queryLogData, TOMBSTONE_POLLING_INTERVAL_MILLISECONDS);
                    }
                    this.queryLogData(); // check for recent logs first, which will check REQUEST_LOGS.status and then trigger this.requestRavenLogUploadAndStartPolling();
                }

                if (this.state.actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.POLLING_FOR_RECENT_REBOOT].status === ACTION_STEP_STATUSES.IN_PROGRESS) {
                    if (this.state.actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.REQUEST_REBOOT].status === ACTION_STEP_STATUSES.COMPLETED) {
                        // make sure polling is active
                        clearInterval(this.queryRavenEventsInterval); // probably not necessary, but doing it anyway for due diligence
                        this.queryRavenEventsInterval = setInterval(this.refreshRavenEvents, TOMBSTONE_POLLING_INTERVAL_MILLISECONDS);
                    }
                    this.checkMostRecentLoadedRavenBootedEventMoment(); // cehck for recent reboot event
                }

                // it was hard to imagine a status at this point that shouldn't just auto-resume from the beginning since checking the logs will jump to the next step if logs present
                // start action from the beginning:
                //seems like overkill: this.setState({...this.initialState}, this.persistStateInLocalStorage);
            });
        }
    }

    componentDidMount() {
        if (this.props.raven && this.props.raven.Id) {
            if (this.props.restoredActionsOverlayState) {
                this.setRestoredActionsOverlayState(); // this will attempt to check request timeouts and resume intervals for the corresponding state
            } else {
                // start action from the beginning:
                this.queryLogData(); // check for recent logs first, which will then trigger this.requestRavenLogUploadAndStartPolling();
            }
        }
    }

    componentWillUnmount() {
        this.persistStateInLocalStorage();
        clearInterval(this.queryLogDataInterval);
        clearInterval(this.queryRavenEventsInterval);
    }

    componentDidUpdate() {//(prevProps, prevState, snapshot) {

        if (this.state.actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.POLLING_FOR_RECENT_REBOOT].status === ACTION_STEP_STATUSES.IN_PROGRESS) {
            this.checkMostRecentLoadedRavenBootedEventMoment();
        }
    }

    requestRavenLogUploadAndStartPolling = () => {

        let actionSteps = this.state.actionSteps;

        const updateActionStepsForLogRequest = (errorMessage = null) => {

            actionSteps = this.state.actionSteps;

            // update log polling step's status icon and supplementary detail
            if (errorMessage) {
                actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.REQUEST_LOGS].status = ACTION_STEP_STATUSES.ERROR;
            } else {
                actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.REQUEST_LOGS].status = ACTION_STEP_STATUSES.COMPLETED;
            }
            actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.REQUEST_LOGS].requestDatetime = new Date().getTime();

            if (errorMessage) {
                this.setState({
                    actionErrorOccurred: true,
                    actionStatusMessage: errorMessage,
                    overridableTimeoutOccurred: true // present a proceed anyway prompt to still allow trying to reboot raven
                }, this.persistStateInLocalStorage);
            } else {
                this.setState({
                    actionSteps: {...actionSteps},
                    actionStatusMessage: null
                }, this.persistStateInLocalStorage);
            }
            // proceed with log polling regardless of error (timeout for new logs will provide an option to proceed anyway)
            clearInterval(this.queryLogDataInterval); // probably not necessary, but doing it anyway for due diligence
            /*
            assumption: POLLING_FOR_RECENT_LOGS status already set via componentDidMount this.queryLogData() call
            let actionSteps = this.state.actionSteps;
            actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.POLLING_FOR_RECENT_LOGS].status = ACTION_STEP_STATUSES.IN_PROGRESS;
            this.setState({ actionSteps: {...actionSteps} });
            */
            this.queryLogDataInterval = setInterval(this.queryLogData, TOMBSTONE_POLLING_INTERVAL_MILLISECONDS);
        };

        actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.REQUEST_LOGS].status = ACTION_STEP_STATUSES.IN_PROGRESS;
        this.setState({
            actionSteps: {...actionSteps},
            actionStatusMessage: "Sending a request for Raven to upload logs."
        }, this.persistStateInLocalStorage);

        const url = this.props.dataStore.prefix2 + "performravenaction"
            + "?stage=" + this.props.stage
            + "&ravenunit=" + this.props.raven['Raven']['Raven UUID']
            + "&action=LOGREQUEST"
            + "&reason=" + encodeURIComponent(this.props.reason);

        fetch(url, {
            method: 'POST',
            headers: { Authorization: CognitoUtil.getCurrentUserToken() }
        })
        .then( (response) => {

            return response.json();
        })
        .then( (json) => {

            if (json.status === "SUCCESS") {
                updateActionStepsForLogRequest(); // passing no error --> success
                return;
            }
            
            // error with message
            if (json.message) {
                updateActionStepsForLogRequest(json.message);
                return;
            }

            // unknown error
            updateActionStepsForLogRequest("Unknown error occurred");

        })
        .catch( (error) => {
            // error sending request
            updateActionStepsForLogRequest(error.message);
        });
    }

    queryLogData = () => {

        const { raven } = this.props;

        if (!raven) {
            this.props.onActionClose();
            return;
        }

        const ravenId = raven.Id;
        const ravenUuid = raven.Raven["Raven UUID"];
        const ravenOSVersion = raven.Build['OS Version'];

        // perform ajax calls to get it the next state
        const url = process.env.REACT_APP_KLOUD_API_BASE_URL + "query/logs"
            + "?stage=" + this.props.stage
            + "&type=tombstones"
            + "&raven=" + ravenId
            + "&uuid=" + ravenUuid
            + "&version=" + ravenOSVersion;

        let actionSteps = this.state.actionSteps;
        actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.POLLING_FOR_RECENT_LOGS].status = ACTION_STEP_STATUSES.IN_PROGRESS;
        this.setState({
            actionSteps: {...actionSteps},
            actionStatusMessage: "Refreshing available logs from raven."
        }, this.persistStateInLocalStorage);

        fetch(url, {
            method: 'POST',
            headers: { Authorization: CognitoUtil.getCurrentUserToken() }
        })
        .then( (response) => {

            if (response.ok) {
                return response.json();
            } else {
                return [];
            }
        })
        .then( (data) => {

            this.checkLogData(data);
        })
        .catch( (error) => {
            actionSteps = this.state.actionSteps;
            actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.POLLING_FOR_RECENT_LOGS].status = ACTION_STEP_STATUSES.ERROR;
            this.setState({
                actionSteps: {...actionSteps},
                actionErrorOccurred: true,
                actionStatusMessage: error.message,
                overridableTimeoutOccurred: true
            }, this.persistStateInLocalStorage);
        });
    }
    checkLogData (tombstones) {

        if (!tombstones || !tombstones.length) {
            return; // no logs yet
        }

        const updateActionStepsForLogStatus = (pollingForRecentLogsStatus, supplementaryDetail = null) => {

            const { actionSteps } = this.state;

            actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.POLLING_FOR_RECENT_LOGS].status = pollingForRecentLogsStatus;
            actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.POLLING_FOR_RECENT_LOGS].supplementaryDetail = supplementaryDetail;

            if (pollingForRecentLogsStatus === ACTION_STEP_STATUSES.IN_PROGRESS) { // if no recent logs are found:

                if (actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.REQUEST_LOGS].status === ACTION_STEP_STATUSES.QUEUED) { // if logs not requested yet:

                    this.requestRavenLogUploadAndStartPolling();

                } else {

                    const logRequestDatetime = this.state.actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.REQUEST_LOGS].requestDatetime;

                    if (logRequestDatetime) {

                        const now = new Date();

                        if (now.getTime() - logRequestDatetime > TOMBSTONE_POLLING_MAX_TIMEOUT_MILLISECONDS) {
                            actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.POLLING_FOR_RECENT_LOGS].status = ACTION_STEP_STATUSES.TIMEOUT;
                            this.setState({
                                actionSteps: {...actionSteps},
                                actionErrorOccurred: true,
                                actionStatusMessage: "New logs appear to be delayed.  It may take several minutes.",
                                overridableTimeoutOccurred: true
                            }, this.persistStateInLocalStorage);
                            return;
                        }
                    }
                }
            } else if (pollingForRecentLogsStatus === ACTION_STEP_STATUSES.COMPLETED) { // if recent logs found:

                // set previous step to request logs as completed (useful if returning to the overlay while global in-progress flag still set)
                actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.REQUEST_LOGS].status = ACTION_STEP_STATUSES.COMPLETED;

                this.requestRavenRebootAndStartPolling();

            }

            this.setState({
                actionSteps: {...actionSteps}
            }, this.persistStateInLocalStorage);
        };

        let mostRecentLogsTimestampDescription;

        for (let i = 0; i < tombstones.length; i += 1) {

            if (!tombstones[i].name) { continue; }

            if (tombstones[i].name.includes("logd")) {

                if (!tombstones[i].LastModified) { continue; }

                const tombstoneLastModified = moment.utc(tombstones[i].LastModified);

                if (moment().diff(tombstoneLastModified, "seconds") < TOMBSTONE_MAX_AGE_SECONDS ) {

                    mostRecentLogsTimestampDescription = tombstoneLastModified.local().format(globalconfig.display.timeFormat) + " (" + tombstoneLastModified.fromNow() + ")";

                    updateActionStepsForLogStatus(ACTION_STEP_STATUSES.COMPLETED);
                    return;

                } else {

                    mostRecentLogsTimestampDescription = tombstoneLastModified.local().format(globalconfig.display.timeFormat) + " (" + tombstoneLastModified.fromNow() + ")";
                }
            }
        }

        if (!mostRecentLogsTimestampDescription) {
            this.setState({
                actionStatusMessage: "No recent logs. Please wait for raven to upload logs.  This may take several minutes."
            }, this.persistStateInLocalStorage);
        } else {
            this.setState({
                actionStatusMessage: "Most recent logs are " + mostRecentLogsTimestampDescription + ". Waiting for newer logs."
            }, this.persistStateInLocalStorage);
        }

        updateActionStepsForLogStatus(ACTION_STEP_STATUSES.IN_PROGRESS); // redundant status update (already in progress), used to check if log request necessary and check for timeout
    }

    checkMostRecentLoadedRavenBootedEventMoment () {

        const { actionSteps } = this.state;
        let mostRecentRebootTimestampDescription;

        if (!this.props.mostRecentLoadedRavenBootedEventMoment) {
            // There are no recent reboot events yet:

            if (this.state.actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.POLLING_FOR_RECENT_REBOOT].status === ACTION_STEP_STATUSES.IN_PROGRESS) {
                this.setState({
                    actionStatusMessage: "Waiting for raven to report a new reboot event."
                }, this.persistStateInLocalStorage);
            }
            // no return (will check for timeout below)

        } else if (moment().diff(this.props.mostRecentLoadedRavenBootedEventMoment, "seconds") < EVENT_RAVEN_REBOOT_MAX_AGE_SECONDS) {
            // Recent reboot event detected and it is recent enough:

            clearInterval(this.queryRavenEventsInterval);

            mostRecentRebootTimestampDescription = this.props.mostRecentLoadedRavenBootedEventMoment.local().format(globalconfig.display.timeFormat) + " (" + this.props.mostRecentLoadedRavenBootedEventMoment.fromNow() + ")";

            actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.POLLING_FOR_RECENT_REBOOT].status = ACTION_STEP_STATUSES.COMPLETED;
            //actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.POLLING_FOR_RECENT_REBOOT].supplementaryDetail = ;
            this.setState({
                actionCompleted: true,
                actionSteps: {...actionSteps},
                actionStatusMessage: "Raven has rebooted successfuly: " + mostRecentRebootTimestampDescription
            }, this.persistStateInLocalStorage);
            return; // reboot was successful

        } else {
            // Recent reboot event detected but it is not recent enough:

            mostRecentRebootTimestampDescription = this.props.mostRecentLoadedRavenBootedEventMoment.local().format(globalconfig.display.timeFormat) + " (" + this.props.mostRecentLoadedRavenBootedEventMoment.fromNow() + ")";

            this.setState({
                actionStatusMessage: "Most recent reboot is " + mostRecentRebootTimestampDescription + ". Waiting for raven to report a newer reboot event."
            }, this.persistStateInLocalStorage);
            // no return (will check for timeout below)
        }

        // at this point, reboot event is still pending (either no event yet, or event is not recent enough):
        const rebootRequestDatetime = this.state.actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.REQUEST_REBOOT].requestDatetime;

        if (rebootRequestDatetime) {

            const now = new Date();

            if (now.getTime() - rebootRequestDatetime > EVENT_RAVEN_REBOOT_POLLING_MAX_TIMEOUT_MILLISECONDS) {

                actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.POLLING_FOR_RECENT_REBOOT].status = ACTION_STEP_STATUSES.TIMEOUT;

                this.setState({
                    actionSteps: {...actionSteps},
                    actionErrorOccurred: true,
                    actionStatusMessage: "Raven reboot appears to be delayed. The raven may not be connected. Click below to retry.",
                    overridableTimeoutOccurred: true // present a proceed anyway prompt to still allow trying to reboot raven
                }, this.persistStateInLocalStorage);
            }
        }
    }

    requestRavenRebootAndStartPolling = () => {

        clearInterval(this.queryLogDataInterval); // stop polling for logs, if still 'running'

        let actionSteps = this.state.actionSteps;

        // check of this is override timeout request:
        if (this.state.overridableTimeoutOccurred) {
            this.setState({
                actionErrorOccurred: false,
                overridableTimeoutOccurred: false
            })
        }

        const updateActionStepsForRebootRequest = (errorMessage = null) => {

            actionSteps = this.state.actionSteps;

            // update log polling step's status icon and supplementary detail
            if (errorMessage) {
                actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.REQUEST_REBOOT].status = ACTION_STEP_STATUSES.ERROR;
            } else {
                actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.REQUEST_REBOOT].status = ACTION_STEP_STATUSES.COMPLETED;
            }
            actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.REQUEST_REBOOT].requestDatetime = new Date().getTime();

            this.setState({ actionSteps: {...actionSteps} });
            if (errorMessage) {
                this.setState({
                    actionErrorOccurred: true,
                    actionStatusMessage: errorMessage,
                    overridableTimeoutOccurred: true // present a retry prompt (leveraging the proceed anyway prompt functionality)
                }, this.persistStateInLocalStorage);
            } else {
                // proceed with polling raven events -- checking for when raven back online:
                let actionSteps = this.state.actionSteps;
                // update POLLING_FOR_RECENT_REBOOT to in progress immediately (but the call will happen until first TOMBSTONE_POLLING_INTERVAL_MILLISECONDS in setInterval below)
                actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.POLLING_FOR_RECENT_REBOOT].status = ACTION_STEP_STATUSES.IN_PROGRESS;
                this.setState({
                    actionSteps: {...actionSteps},
                    actionStatusMessage: "Reboot request sent. Waiting for Raven to come back online."
                }, this.persistStateInLocalStorage);
                clearInterval(this.queryRavenEventsInterval); // probably not necessary, but doing it anyway for due diligence
                this.queryRavenEventsInterval = setInterval(this.refreshRavenEvents, TOMBSTONE_POLLING_INTERVAL_MILLISECONDS);
            }
        };

        actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.REQUEST_REBOOT].status = ACTION_STEP_STATUSES.IN_PROGRESS;
        this.setState({
            actionSteps: {...actionSteps},
            actionStatusMessage: "Now sending a request for Raven to reboot ..."
         }, this.persistStateInLocalStorage);

        const url = this.props.dataStore.prefix2 + "performravenaction"
            + "?stage=" + this.props.stage
            + "&ravenunit=" + this.props.raven['Raven']['Raven UUID']
            + "&action=REBOOT"
            + "&reason=" + encodeURIComponent(this.props.reason);

        fetch(url, {
            method: 'POST',
            headers: { Authorization: CognitoUtil.getCurrentUserToken() }
        })
        .then( (response) => {

            return response.json();
        })
        .then( (json) => {

            if (json.status === "SUCCESS") {
                updateActionStepsForRebootRequest();
                return;
            }
            
            // error with message
            if (json.message) {
                updateActionStepsForRebootRequest(json.message);
                return;
            }

            // unknown error
            updateActionStepsForRebootRequest("Unknown error occurred");

        })
        .catch( (error) => {
            // error sending request
            updateActionStepsForRebootRequest(error.message);
        });
    }

    refreshRavenEvents = () => {

        if (this.props.feature) {
            this.props.dataStore.getRavenEvents(this.props.feature); // for Diagnostics component 'Last reboot time' and also related to Actions event polling
        }

        let actionSteps = this.state.actionSteps;
        actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.POLLING_FOR_RECENT_REBOOT].status = ACTION_STEP_STATUSES.IN_PROGRESS;
        this.setState({
            actionSteps: {...actionSteps},
            actionStatusMessage: "Refreshing recent events from raven."
        }, this.persistStateInLocalStorage);

    }

    iconElementForStatus = (status) => {

        let statusIconElement;
        if (status === ACTION_STEP_STATUSES.IN_PROGRESS || status === ACTION_STEP_STATUSES.TIMEOUT) {
            statusIconElement = <span className="action-step-status-icon"><ActivityIndicator /></span>;
        } else {
            statusIconElement = <span className="material-icons-outlined action-step-status-icon">{ACTION_STEP_STATUS_ICON_IDS[status]}</span>;
        }
        return statusIconElement;
    }

    render () {

        let actionMessageClassName = "action-message";
        if (this.state.actionErrorOccurred) {
            actionMessageClassName += " error";
        }

        let proceedAnywayButton = null;
        if (this.state.overridableTimeoutOccurred) {
            proceedAnywayButton = <button className="message-button" onClick={this.requestRavenRebootAndStartPolling}>Proceed Anyway</button>
        }

        return (
            <div className="raven-actions-pending-overlay">
                {this.state.actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.POLLING_FOR_RECENT_REBOOT].status === ACTION_STEP_STATUSES.COMPLETED ?
                    <header>
                        Reboot action completed
                        <button className="success-button" disabled={this.state.cancelButtonIsDisabled} onClick={this.onClose}>Dismiss</button>
                    </header>
                :
                    <header>
                        Reboot action in progress
                        <button className="close-button" disabled={this.state.cancelButtonIsDisabled} onClick={this.onClose}>Cancel</button>
                    </header>
                }

                <div className="action-step">
                    {this.iconElementForStatus(this.state.actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.REQUEST_LOGS].status)}
                    {this.state.actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.REQUEST_LOGS].description}
                </div>
                <div className="action-step">
                    {this.iconElementForStatus(this.state.actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.POLLING_FOR_RECENT_LOGS].status)}
                    {this.state.actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.POLLING_FOR_RECENT_LOGS].description}
                </div>
                <div className="action-step">
                    {this.iconElementForStatus(this.state.actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.REQUEST_REBOOT].status)}
                    {this.state.actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.REQUEST_REBOOT].description}
                </div>
                <div className="action-step">
                    {this.iconElementForStatus(this.state.actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.POLLING_FOR_RECENT_REBOOT].status)}
                    {this.state.actionSteps[ACTION_REBOOT_RAVEN_STEP_IDS.POLLING_FOR_RECENT_REBOOT].description}
                </div>
                
                <div className={actionMessageClassName}>
                    <div>{this.state.actionStatusMessage}</div>
                    {proceedAnywayButton}
                </div>
            </div>
        );
    }
}

class ActivityIndicator extends React.PureComponent {
    render () {
        return <div className="activity-indicator"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    }
}
