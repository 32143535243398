import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import ActionsRebootRavenOverlay from './actionsRebootRavenOverlay';

const ACTION_REASON_MAX_CHARACTERS = 280;
export default class Actions extends React.PureComponent {

    static propTypes = {
        dataStore: PropTypes.object.isRequired, // for requesting refresh for missing details on app header sort displayVariable changes
        item: PropTypes.object.isRequired,
        feature: PropTypes.object.isRequired, // TODO feature contains item (above), so one prop could be passed instead of two
        oldestLoadedEventMoment: PropTypes.object, // moment.js
        mostRecentLoadedRavenBootedEventMoment: PropTypes.object, // moment.js    
        stage: PropTypes.string.isRequired
    };

    initialState = {
        selectedActionValue: null,
        selectedActionReasonValue: null,
        actionsReasonErrorOccurred: false,
        actionsSelectErrorOccurred: false,
        actionRequestInProgress: false,
        restoredActionsOverlayState: null
    };

    constructor(props) {
        super(props);

        this.state = this.initialState;

        this.queryLogDataInterval = undefined; // polling logs
        this.queryRavenEventsInterval = undefined; // polling for raven back online
    }

    availableActions = [{
        value: "restart",
        label: "Restart raven",
        id: "restart"
    }];

    componentDidMount() {
        
        if (this.props.feature) {
            const stateToRestore = this.loadStateFromLocalStorage();

            if (stateToRestore) {
                this.removeStateInLocalStorage();
                this.setState(stateToRestore);
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const previousPropsItem = prevProps.item;
        const currentPropsItem = this.props.item;

        if (previousPropsItem.Id && currentPropsItem.Id) {

            if (previousPropsItem.Id !== currentPropsItem.Id) {

                const stateToRestore = this.loadStateFromLocalStorage();

                if (stateToRestore) {
                    this.removeStateInLocalStorage();
                    this.setState(stateToRestore);
                } else {
                    this.setState(this.initialState);
                }
            }
        } 
    }

    removeStateInLocalStorage = () => {

        const ravenId = this.props.item && this.props.item.Id ? this.props.item.Id : undefined;

        if (!ravenId) { return };

        const key = "ravens.actions." + ravenId; // only reboot is supported at the moment

        window.localStorage.removeItem(key);
    }

    persistStateInLocalStorage = (actionsOverlayState) => {

        const ravenId = this.props.item && this.props.item.Id ? this.props.item.Id : undefined;

        if (!ravenId) { return };

        const key = "ravens.actions." + ravenId; // only reboot is supported at the moment

        const stateToPersist = this.state;

        stateToPersist.restoredActionsOverlayState = actionsOverlayState;

        window.localStorage.setItem(key, JSON.stringify(stateToPersist));
    }

    loadStateFromLocalStorage = () => {

        const ravenId = this.props.item && this.props.item.Id ? this.props.item.Id : undefined;

        if (!ravenId) { return };

        const key = "ravens.actions." + ravenId;

        const combinedStatesJSON = window.localStorage.getItem(key);

        if (combinedStatesJSON) {
            return JSON.parse(combinedStatesJSON);
        }
    }

    onActionSubmit = () => {
        let validationFailed = false;
        if (!this.state.selectedActionValue) {
            this.setState({actionsSelectErrorOccurred: true});
            validationFailed = true;
        } else {
            this.setState({actionsSelectErrorOccurred: false});
        }
        if (!this.state.selectedActionReasonValue) {
            this.setState({actionsReasonErrorOccurred: true});
            validationFailed = true;
        } else {
            this.setState({actionsReasonErrorOccurred: false});
        }
        if (validationFailed) {
            return;
        }
        this.setState({actionRequestInProgress: true});
    }

    onActionCancel = () => {
        this.setState({...this.initialState}, this.removeStateInLocalStorage());
    }

    onActionClose = () => {
        this.setState({...this.initialState}, this.removeStateInLocalStorage());
    }

    render () {

        let actionsSelectClassName = "actions-select";
        let textareaClassName = "actions-reason-textarea";
        let textareaLabelClassName = "";
        let textAreaCharacterLimitClassName = "actions-reason-character-limit";
        if (this.state.actionsSelectErrorOccurred) {
            actionsSelectClassName += " error";
        }
        if (this.state.actionsReasonErrorOccurred) {
            textareaClassName += " error-border";
            textareaLabelClassName += " error-highlight";
        }
        const textAreaInputChractersLength = this.state.selectedActionReasonValue ? this.state.selectedActionReasonValue.length : 0;
        if (textAreaInputChractersLength === ACTION_REASON_MAX_CHARACTERS) {
            textAreaCharacterLimitClassName += " max-reached";
        }

        return (
            <section className="raven-actions">
                <header>
                    Raven Actions
                </header>
                <div className="raven-actions-container">
                    <div>
                        <Select
                            id="actions-select"
                            className={actionsSelectClassName}
                            name="actions-select"
                            placeholder="Select an action"
                            options={this.availableActions}
                            value={this.state.selectedActionValue}
                            onChange={(selectedActionValue) => {
                                this.setState({
                                    selectedActionValue,
                                    actionsSelectErrorOccurred: false
                                })}}
                            searchable={false/* disable input */}
                        />
                    </div>
                    <div>
                        <div className={textareaLabelClassName} >Reason for action:</div>
                        <textarea
                            name="actions-reason"
                            value={this.state.selectedActionReasonValue ? this.state.selectedActionReasonValue : ""}
                            onChange={(event) => {this.setState({
                                selectedActionReasonValue: event.target.value.substring(0, ACTION_REASON_MAX_CHARACTERS),
                                actionsReasonErrorOccurred: false
                            })}}
                            className={textareaClassName}
                        />
                        <div className={textAreaCharacterLimitClassName}>
                            <span>
                                {this.state.selectedActionReasonValue ? this.state.selectedActionReasonValue.length : 0}/{ACTION_REASON_MAX_CHARACTERS}
                            </span>
                        </div>
                    </div>
                    <div className="form-buttons">
                        <button className="btn actions-submit" onClick={this.onActionSubmit} >Send Request To Raven</button>
                    </div>
                </div>
                {this.state.actionRequestInProgress? 
                    <ActionsRebootRavenOverlay
                        dataStore={this.props.dataStore}
                        raven={this.props.item}
                        feature={this.props.feature}
                        oldestLoadedEventMoment={this.props.oldestLoadedEventMoment}
                        mostRecentLoadedRavenBootedEventMoment={this.props.mostRecentLoadedRavenBootedEventMoment}
                        stage={this.props.stage}
                        reason={this.state.selectedActionReasonValue}
                        onActionCancel={this.onActionCancel}
                        onActionClose={this.onActionClose}
                        persistStateInLocalStorage={this.persistStateInLocalStorage}
                        restoredActionsOverlayState={this.state.restoredActionsOverlayState}
                    />
                :
                    null
                }
            </section>
        )
    }
}
