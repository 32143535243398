import React from 'react';
import Select from 'react-select';

import 'react-select/dist/react-select.css';

export default class StageSelector extends React.PureComponent {

    onSelectRavenStage = (option) => {
        if (!option) return;
        this.props.onRavenStageChange(option.value);
    }

    render() {

        if (!this.props.ravenStages) return;

        const stages = this.props.ravenStages.map(obj => {
                return { value : obj,
                    label: obj,
                    id: obj,
                };
            });

        return (
            <Select id="ravenStage" 
                placeholder="Stage..."
                options={stages} 
                value={this.props.stage} 
                name="ravenStage"
                onChange={this.onSelectRavenStage} 
                clearable={false} searchable={false} />
        );
    }
}
