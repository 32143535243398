import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import StageSelector from '../../../common/StageSelector';

export default class Header extends React.PureComponent {

    render() {
        return (
            <header>
                <Link to="/" className="logo"></Link>
                <div className="controls">
                    <StageSelector
                        ravenStages = {this.props.ravenStages}
                        stage = {this.props.stage}
                        onRavenStageChange = {this.props.onRavenStageChange}
                    />
                </div>
            </header>
        );
    }
}


Header.propTypes = {
    ravenStages: PropTypes.array.isRequired,
    stage: PropTypes.string.isRequired,
    onRavenStageChange: PropTypes.func.isRequired,
};