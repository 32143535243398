import React from 'react';

const DEBOUNCE_TIME = 500;
const MAX_LENGTH = 50;

export default class SearchBox extends React.PureComponent {

    state = {
        keyword: this.props.keyword || '',
        showResults: false,
        foundNoMatches: false,
        hasFocus: false,
        placeholder: this.props.placeholder || 'Search...'
    }

    id = 'search-box-' + crypto.randomUUID();

    debounceTimer = null;

    componentDidMount() {
        document.addEventListener('click', this.handleFocusAndBlur);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleFocusAndBlur);
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.inProgress && !this.props.inProgress) {
            this.setState({showResults: this.props.results.length > 0, foundNoMatches: this.props.results.length === 0});
        }

        if(prevProps.keyword !== this.props.keyword) {
            this.setState({ keyword: this.props.keyword });
        }
    }

    validate = (value) => {
        if(value.length > MAX_LENGTH) return false;
        return true;
    }

    isEmpty = (value) => {
        return value.trim().length === 0;
    }

    undo = () => {
        this.setState({ keyword: this.props.keyword });
    }

    reset = () => {
        this.setState({ 
            keyword: '', 
            showResults: false, 
            foundNoMatches: false,
        });

        if(this.props.onReset) this.props.onReset();
    }

    onChange = (e) => {

        if(this.isEmpty(e.target.value)) {
            this.reset();
            return;
        }

        if(!this.validate(e.target.value)) return;

        this.setState({ keyword: e.target.value});

        // Only proceed if the parent component has an onChange prop
        if(!this.props.onChange) return;

        if(this.debounceTimer)  clearTimeout(this.debounceTimer);
        
        this.debounceTimer = setTimeout(() => this.callParentOnChange(), DEBOUNCE_TIME);        
    }

    callParentOnChange = () => {
        this.props.onChange(this.state.keyword);
        this.debounceTimer = null;
    }

    onSelect = (e, value) => {
        this.props.onSelect(value);
        this.setState({ 
            keyword: e.target.innerText,
            showResults: false
        });
    }

    handleFocusAndBlur = (e) => {
        if(!e.target.closest(`#${this.id}`)) {
            this.onBlur();
        }
        else {
            this.onFocus(e);
        }
    }

    onFocus = (e) => {
        this.setState({ 
            hasFocus: true,
            showResults: this.props.results && this.props.results.length > 0
         });
         e.target.select();
    }

    onBlur = () => {
        this.setState({ 
            hasFocus: false,
            showResults: false
        });
    }

    onKeyUp = (e) => {
        // Hitting Enter key
        if(e.keyCode === 13) {
            this.search();
        }
    }

    search = () => {
        if(this.props.onSearch)
            this.props.onSearch(this.state.keyword);
    }

    render() {
        const classes = this.state.hasFocus ? 'search-box has-focus' : 'search-box';
        return (
            <div className={classes} id={this.id}>
                <input type="text" value={this.state.keyword} onKeyUp={this.onKeyUp} placeholder={this.state.placeholder} onChange={this.onChange} maxLength={MAX_LENGTH} />
                {this.state.showResults ?
                    <div className='search-results'>
                        <ul>
                            {this.props.results.map((result, i) => {
                                return <li onClick={(e) => this.onSelect(e, this.props.valueProp ? result[this.props.valueProp] : result)} key={i}>{this.props.textProp ? result[this.props.textProp] : result}</li>
                            })}
                        </ul>
                    </div>
                    : this.props.inProgress ? 
                        <div className='search-results'><ul><li>Searching...</li></ul></div> 
                        : (this.state.foundNoMatches && this.state.hasFocus) ? 
                            <div className='search-results'><ul><li>No Matches</li></ul></div> 
                            : null
                }
                <div className='buttons'>
                {
                    this.props.keyword && this.props.keyword.length > 0 && this.props.keyword !== this.state.keyword && this.state.hasFocus && <button onClick={this.undo} title="Undo changes" className='undo-button'><i className="material-icons">undo</i></button>
                }
                {
                    this.state.keyword && this.state.keyword.length > 0 && this.state.hasFocus && <button onClick={this.reset} title="Clear the searchbox" className='clear-button'><i className="material-icons">close</i></button>
                }
                {
                    this.props.onSearch && <button onClick={this.search} title="Search" className='search-button'><i className="material-icons">search</i></button>
                }
                </div>
            </div>
        );
    }
}
