import {MAX_LENGTH} from "./constants";
export default {
    btMac: [
        {
            isValid: (v) => v.length === MAX_LENGTH.MAC_ADRESS,
            message: `MAC Address must be ${MAX_LENGTH.MAC_ADRESS} characters long`
        },
        {
            isValid: (v) => /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/.test(v),
            message: "MAC Address must be in the format 00:00:00:00:00:00"
        }
    ],
    make: [
        {
            isValid: (v) => v.length <= MAX_LENGTH.MAKE,
            message: `Make must be less than ${MAX_LENGTH.MAKE} characters long`
        }
    ],
    model: [
        {
            isValid: (v) => v.length <= MAX_LENGTH.MODEL,
            message: `Model must be less than ${MAX_LENGTH.MODEL} characters long`
        }
    ]
};