import moment from 'moment';
import globalconfig from '../common/config';

let globalconfigFeaturesRavensVendorDetailsPreferred = false; // ternary-friendly version of globalconfig.features.ravens.vendorDetailsPreferred
if (globalconfig.features &&
    globalconfig.features.ravens &&
    globalconfig.features.ravens.hasOwnProperty('vendorDetailsPreferred') ) {
    globalconfigFeaturesRavensVendorDetailsPreferred = globalconfig.features.ravens.vendorDetailsPreferred;
}

var displayVariableTypes = [
    { 
        value: "ts", 
        label: 'Last Report Time',
        name: 'Last Update',
        type: 'date',
        renderMethod: (val) => {
            if(val < 10000)
                return "Not Seen";
            return moment.unix(val).fromNow();
        },
        sortMethod: (a, b, desc) => {
            // force null and undefined to the bottom
            if(a === null || a === undefined)
            {
                return -1;
            }
            if(b === null || b === undefined)
            {
                return 1;
            }

            return a - b;
        }
    },
    { 
        value: "state", 
        label: 'Engine State',
        section: 'Raven', 
        name: 'State' 
    },
    { 
        value: "gps_accuracy", 
        label: 'GPS Accuracy',
        section: 'Vehicle',
        name: 'GPS Accuracy' 
    },
    { 
        value: "gps_speed", 
        label: 'GPS Speed',
        section: 'Vehicle',
        name: 'GPS Speed',
        groups: [0, 1, 40, 80, 120],
        colors: ['LightSkyBlue', 'Yellow', 'Yellow', 'Gold', 'Red']
    },
    { 
        value: "uptime", 
        label: 'Raven Uptime',
        section: 'Raven',
        name: 'Uptime',
        type: 'time'
    },
    { 
        value: "raven_temperature", 
        label: 'Raven Temperature',
        section: 'Raven',
        name: 'Raven Temperature',
        groups: [50, 65, 75, 80, 90, 100, 120],
        colors: ['#87cefa','#49f69a','#d3ef4c','#ffdc00','#ff9000','#ff0000','#ff0000']
    },
    ... globalconfigFeaturesRavensVendorDetailsPreferred ? [] : [{  // using conditional spread to either include 'Raven Battery Voltage (internal)' or skip
        value: "raven_battery_voltage", 
        label: 'Raven Battery Voltage (internal)' ,
        section: 'Raven',
        name: 'Raven Battery Voltage',
    }],
    { 
        value: "obd_battery_voltage", 
        label: 'Battery Voltage (vehicle)' ,
        section: 'Vehicle',
        name: 'OBD Battery Voltage',
        groups: [10000, 11000, 11900, 12000, 12500, 13000],
        colors: ['CornflowerBlue', 'Red', 'Red', 'Orange', 'Yellow', 'Lime']
    },
    ... globalconfigFeaturesRavensVendorDetailsPreferred ? [] : [{  // using conditional spread to either include 'Altitude' or skip
        value: "altitude", 
        label: 'Altitude' ,
        section: 'Vehicle',
        name: 'Altitude' ,
    }],
    { 
        value: "raven_os_version", 
        label: 'OS Version' ,
        section: 'Build',
        name: 'OS Version' ,
    },
    ... globalconfigFeaturesRavensVendorDetailsPreferred ? [] : [{  // using conditional spread to either include 'Account Accessible' or skip
        value: "accessible", 
        label: 'Account Accessible' ,
        section: 'Account',
        name: 'Account Accessible' ,
    }],
    { 
        value: "vehicle_type", 
        label: 'Vehicle Type' ,
        section: 'Vehicle',
        name: 'Vehicle Type' ,
    },
    { 
        value: "vehicle_make", 
        label: 'Vehicle Make' ,
        section: 'Vehicle',
        name: 'Make' ,
    },
    { 
        value: "vehicle_model", 
        label: 'Vehicle Model' ,
        section: 'Vehicle',
        name: 'Model' ,
    },
    { 
        value: "vehicle_year", 
        label: 'Vehicle Year' ,
        section: 'Vehicle',
        name: 'Year' ,
    },
    ... globalconfigFeaturesRavensVendorDetailsPreferred ? [] : [{  // using conditional spread to either include 'Service Plan' or skip
        value: "plan_name", 
        label: 'Service Plan' ,
        section: 'Plan',
        name: 'Service Plan Name' ,
    }],
    ... globalconfigFeaturesRavensVendorDetailsPreferred ? [] : [{  // using conditional spread to either include 'Service Plan Status' or skip
        value: "plan_status", 
        label: 'Service Plan Status' ,
        section: 'Plan',
        name: 'Service Plan Status' ,
    }],
    ... globalconfigFeaturesRavensVendorDetailsPreferred ? [] : [{  // using conditional spread to either include 'Sim Rate Plan' or skip
        value: "sim_rate_plan", 
        label: 'Sim Rate Plan' ,
        section: 'Plan',
        name: 'Sim Rate Plan' ,
    }],
    ... globalconfigFeaturesRavensVendorDetailsPreferred ? [] : [{  // using conditional spread to either include 'Sim Provider' or skip
        value: "sim_provider", 
        label: 'Sim Provider' ,
        section: 'SIM',
        name: 'Sim Provider' ,
    }],
    { 
        value: "sdcard_status", 
        label: 'SD Card Status' ,
        section: 'Raven',
        name: 'SD Card Status' ,
    },
    { 
        value: "raven_unit_status", 
        label: 'Raven Unit Status' ,
        section: 'Raven',
        name: 'Raven Unit Status' ,
    },
    { 
        value: "total_data_usage", 
        label: 'Total Data Usage' ,
        section: 'Current Billing Period',
        name: 'Total Data Usage' ,
        groups: [200, 400, 800, 900, 950, 1000, 9999],
        colors: ['#87cefa','#49f69a','#d3ef4c','#ffdc00','#ff9000','#ff4000','#ff0000']
    },
    ... globalconfigFeaturesRavensVendorDetailsPreferred ? [] : [{  // using conditional spread to either include 'Fan Type' or skip
        value: "fan_type", 
        label: 'Fan Type' ,
        section: 'Raven',
        name: 'Fan Type' ,
    }],
    { 
        value: "num_gestures", 
        label: 'Num gestures in last 24 hours' ,
        section: 'Raven',
        name: 'Num gestures in last 24 hours' ,
    },
    { 
        value: "canbus_type", 
        label: 'CANBus Type' ,
        section: 'Vehicle',
        name: 'CANBus Type' ,
    },
    ... globalconfigFeaturesRavensVendorDetailsPreferred ? [] : [{  // using conditional spread to either include 'Account Type' or skip
        value: "account_type", 
        label: 'Account Type' ,
        section: 'Account',
        name: 'Account Type' ,
    }],
    ... globalconfigFeaturesRavensVendorDetailsPreferred ? [] : [{  // using conditional spread to either include 'Channel Name' or skip
        value: "channel_name", 
        label: 'Channel Name' ,
        section: 'Account',
        name: 'Channel Name' ,
    }],
];


export default displayVariableTypes;
