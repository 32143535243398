
export const MAIN_FILTER_TYPES = {
    SHOW_ALL: "SHOW_ALL",
    SHOW_ONLY_ASSIGNED: "SHOW_ONLY_ASSIGNED",
    SHOW_ONLY_UNASSIGNED: "SHOW_ONLY_UNASSIGNED"
}

export const MAX_LENGTH = {
    MAKE: 50,
    MODEL: 50,
    MAC_ADRESS: 17
}

export const PLACEHODERS = {
    MAKE: "",
    MODEL: "",
    MAC_ADRESS: "00:00:00:00:00:00"
}

export const GET_BEACONS_TIMEOUT = 15000;
export const HIDE_MESSAGES_TIMEOUT = 4000;