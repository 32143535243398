import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';

import Events from '../../../common/events';

import 'react-select/dist/react-select.css';

import globalconfig from '../../../common/config';

import displayVariableTypes from '../../../common/displayVariableTypes';

export default class Header extends Component {

    constructor () {
        super();

        this.ravenTypes = [
          { value: 'active', label: 'Active Ravens' },
          { value: 'owned', label: 'Owned Ravens' },
          { value: 'all', label: 'All Ravens' },
          { value: 'lastday', label: 'Ravens Provisioned last 24h' },
          { value: 'last2days', label: 'Ravens Provisioned last 48h' },
          { value: 'lastweek', label: 'Ravens Provisioned last week' },
          { value: 'lastmonth', label: 'Ravens Provisioned last month' },
          { value: 'unprovisioned', label: 'Unprovisioned Ravens' },
          { value: 'returned', label: 'Returned Ravens' },
          { value: 'error', label: 'Ravens in Odd State' },
        ];

        this.state = {
            ravenTypes: null,
            curRavenType: this.ravenTypes[0].value,
            displayVariable: displayVariableTypes[0],
        };
    }

    setRavenStages(nextProps) {
        this.ravenStages = nextProps.ravenStages.map(obj => {
                return { value : obj,
                    label: obj,
                    id: obj,
                };
            });
    }

    componentDidMount() {

        this.setRavenStages(this.props);

        // After component mounted, fire the active display type
        this.props.onRavenDisplayTypeChange(this.ravenTypes[0].value, displayVariableTypes[0]);
        this.props.onRavenDisplayVariableChange(this.ravenTypes[0].value, displayVariableTypes[0]);
    }

    componentWillReceiveProps(nextProps) {
        this.setRavenStages(nextProps);

        this.ravenList = nextProps.ravenList.map(obj => {

            let value = '';
            let label = '';

            if (globalconfig.features.ravens.vendorDetailsPreferred && obj['Raven']['Raven External Id']) {//obj['Raven'].hasOwnProperty('Raven External Id')) {
                const ravenIdName = globalconfig.features.ravens.externalIdOptionalTitle || 'External Id';
                value = '' + obj['Id']  + ' ' + obj['Account']['Account Name'] + ' (' + obj['Raven']['Unit Id'] + ')';
                label = ravenIdName + ':\n    ' + obj['Raven']['Raven External Id'] + '\n' + 'Account:\n    ' + obj['Account']['Account Name'];
            } else if (globalconfig.features.ravens.vendorDetailsPreferred && obj['Raven'].hasOwnProperty('Enclosure Serial No.')) {
                value = '' + obj['Id']  + ' ' + obj['Account']['Account Name'] + ' (' + obj['Raven']['Unit Id'] + ')';
                label = 'Serial:\n    ' + obj['Raven']['Enclosure Serial No.'] + '\n' + 'Account:\n    ' + obj['Account']['Account Name'];
            } else {
                value = '' + obj['Id']  + ' ' + obj['Account']['Email'] + ' (' + obj['Raven']['Unit Id'] + ')';
                label = '' + obj['Raven']['Unit Id'] + ' ' + obj['Account']['Email'];
            }

            return { value: value,
                label: label,
                id: obj['Id'],
                enclosureid: obj['Raven']['Enclosure Serial No.'],
                obj: obj
            };
        });
    }

    onSelectRavenStage = (val) => {
      console.log('Selected Raven Stage: ', val);

      if(!val) return;

      this.props.onRavenStageChange(val.value, this.state.curRavenType, this.state.displayVariable);

    }

    onSelectRavenSearch = (val) => {
        /* Important hint for how this works currently:
            1 - mapboxMap.js is the only listener for the following event dispatch
            2 - mapboxMap.js' event handler triggers onFeatureClick() in parent ravenPage.js
            3 - ravenPage.js' onFeatureClick() only triggers a URL redirect (history push)
            4 - ravenPage.js has a Router configuration which captures the ravenUuid URL parameter while rendering featureSheet.js
            5 - featureSheet.js' componentDidUpdate() detects the selected raven and calls props.dataStore.queryRaven() from ravenPage.js
            7 - ravenDataStore queryRaven() will eventually fire the callback dataChangeCallback in ravenPage.js to update 'ravenData' in state
            8 - featureListing.js, mapboxMap.js, and featureSheet.js are subsequently updated using ravenPage.js's state
        */

        document.dispatchEvent(new CustomEvent(Events.RAVEN_SELECT_EVENT,
                { detail: { 
                    message: 'Selecting Raven ' + val.label,
                    ravenid: val.id,
                    enclosureid: val.enclosureid
                } }));
        this.props.applyFilterRavenId(val.id);
    }

    onSelectRavenType = (val) => {
      console.log('Selected Raven Type: ', val);

      this.setState({curRavenType: val.value});

      this.props.onRavenDisplayTypeChange(val.value, this.state.displayVariable);
    }

    onSelectDisplayVariable = (val) => {
      console.log('Selected Display Variable: ', val); 

      this.props.onRavenDisplayVariableChange(this.state.curRavenType, val);

      this.setState({displayVariable: val});
    }

    render() {

        return (
            <div className="row" id="controls">
                <div className="branding col-2 raven-logo">
                    <Link to="/"></Link>
                </div>
                <div className="viewOptions col-10">
                    <div className="row">
                        <div className="col-3" >
                            <Select id="ravenStage" 
                                placeholder="Stage..."
                                options={this.ravenStages} 
                                value={this.props.stage} 
                                name="ravenStage" 
                                onChange={this.onSelectRavenStage} 
                                clearable={false} searchable={false} />
                        </div>
                        <div className="col-3" >
                            <Select id="ravenSearch" 
                                placeholder="Search Ravens or Accounts"
                                searchable={true} 
                                options={this.ravenList}  
                                name="ravenSearch" 
                                filterOption={(option, filter) => {
                                    // Search: ravenid, raven unit id, email, raven enclosure id 
                                    if(option.obj['Id'].toString().includes(filter))
                                        return true;
                                    if('Serial No.' in option.obj['Raven'] && option.obj['Raven']['Serial No.'])
                                    {
                                        if(option.obj['Raven']['Serial No.'].toLowerCase().includes(filter))
                                            return true;
                                    }
                                    if('Email' in option.obj['Account'] && option.obj['Account']['Email'])
                                    {
                                        if(option.obj['Account']['Email'].toLowerCase().includes(filter))
                                            return true;
                                    }
                                    if('Account Name' in option.obj['Account'] && option.obj['Account']['Account Name'])
                                    {
                                        if(option.obj['Account']['Account Name'].toLowerCase().includes(filter))
                                            return true;
                                    }
                                    if('Raven External Id' in option.obj['Raven'] &&
                                        option.obj['Raven']['Raven External Id'])
                                    {
                                        if(option.obj['Raven']['Raven External Id'].toLowerCase().includes(filter))
                                            return true;
                                    }
                                    if('Enclosure Serial No.' in option.obj['Raven'] &&
                                        option.obj['Raven']['Enclosure Serial No.'])
                                    {
                                        if(option.obj['Raven']['Enclosure Serial No.'].toLowerCase().includes(filter))
                                            return true;
                                    }
                                    if('Unit Id' in option.obj['Raven'] && option.obj['Raven']['Unit Id'])
                                    {
                                        if(option.obj['Raven']['Unit Id'].toString().includes(filter))
                                            return true;
                                    }
                                    if('Raven UUID' in option.obj['Raven'] && option.obj['Raven']['Raven UUID'])
                                    {
                                        if(option.obj['Raven']['Raven UUID'].toString().includes(filter))
                                            return true;
                                    }
                                    if('IMEI' in option.obj['Raven'] && option.obj['Raven']['IMEI'])
                                    {
                                        if(option.obj['Raven']['IMEI'].toString().includes(filter))
                                            return true;
                                    }
                                    if('Name' in option.obj['Vehicle'] && option.obj['Vehicle']['Name'])
                                    {
                                        if(option.obj['Vehicle']['Name'].toString().toLowerCase().includes(filter))
                                            return true;
                                    }

                                    return false;
                                }}
                                onChange={this.onSelectRavenSearch} 
                                clearable={false} />
                        </div>
                        <div className="col-3" >
                            <Select id="ravenType" 
                                placeholder="Raven Types..."
                                options={this.ravenTypes} 
                                value={this.state.curRavenType} 
                                name="ravenType" 
                                onChange={this.onSelectRavenType} 
                                clearable={false} searchable={false} />
                        </div>

                        <div className="col-3">
                            <Select id="displayVariable" 
                                placeholder="Color..."
                                options={displayVariableTypes} 
                                value={this.state.displayVariable.value} 
                                name="displayVariable" 
                                onChange={this.onSelectDisplayVariable} 
                                clearable={false} 
                                searchable={false} />
                        </div>
                    </div>

                </div>
            </div>

        );
    }
}
