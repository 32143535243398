import React, { Component, Fragment } from 'react';
import Select from 'react-select';
import moment from 'moment';
import { isInclusivelyBeforeDay, SingleDatePicker } from 'react-dates';

import Dialog from './dialog';

import CognitoUtil from '../../../aws/cognito/cognitoUtil';
import useScanDetection from 'use-scan-detection';


/*
    Class to poll the OTA status as it is progressing
*/
export class RemoveOrderModal extends Component {
    constructor (props) {
        super(props);

        this.state = {
            orderno: null,
            comment: null,
        };
    }

    onOrderChange = (val) => {
        this.setState({orderno: val});
    }

    onCommentChange = (val) => {
        this.setState({comment: val});
    }

    onRemoveOrder = () => {
        console.log("Removing ", this.state.orderno);
        this.props.onSuccess(this.state.orderno.value, this.state.comment);

        this.props.onClose();
    }

    render() {

        var title = "Cancel Order";

        if(this.props.data && this.props.data.account && this.props.data.account.email)
        {
            title += " for ";
            title += this.props.data.account.email;
        }

        var orders = this.props.data.orders.filter((elem) => {
                return !elem.canceled;
            }).map((elem) => {
                return { value: elem.orderNumber, label: elem.orderNumber + ": " + elem.createDate + " (" + elem.quantity + " Ravens)" };
        });

        return (
            <Dialog
                size="small"
                label={title}
                isOpen={this.props.isOpen}
                onClose={this.props.onClose}
                >

                <div className="row">
                    <div className="col-3">
                        Order Number:
                    </div>
                    <div className="col-9">
                        <Select id="orderno"
                        placeholder="Order..."
                        searchable={true}
                        value={this.state.orderno}
                        options={orders}
                        name="orderno"
                        onChange={this.onOrderChange}
                        clearable={false} />
                    </div>
                    <div className="col-3">
                        *Comment:<br />
                        <small>(*Required)</small>
                    </div>
                    <div className="col-9">
                        <input name="comment" 
                            type="text" 
                            onChange={(e) => { this.onCommentChange(e.target.value) }}
                            value={this.state.comment}
                        />
                    </div>
                    <div className="col-12">
                        <div align="right">
                            <button onClick={this.props.onClose}>Do Nothing</button> 
                            { this.state.comment ? 
                                <button onClick={this.onRemoveOrder}>Cancel Order</button> :
                                <button disabled onClick={this.onRemoveOrder}>Cancel Order</button>
                            }
                        </div>
                    </div>

                </div>


            </Dialog>
        );
    }

}


const RavenSerialNumberInput = (props) => {

    useScanDetection({
        onComplete: props.setOnComplete,
        minLength: 12 // raven1 sn length 12, raven2 sn length 13
    });

    return (
        <input
            name={`serialnumberinput-${props.overallIdx}`}
            value={props.serialNumbers[props.productIdx]}
            data-product={props.product}
            type={props.type}
            onChange={props.setOnChange}
            //onComplete={props.setOnComplete} // Using this handler here throws a warning "Warning: Unknown event handler property `onComplete`. It will be ignored."
            onFocus={props.setOnFocus}
        />
    );
};

/* Class to ask the user to perform OTA and to display the result from das Kloud
*/
export class AddOrderModal extends Component {
    constructor (props) {
        super(props);

        this.state = {
            orderno: null,
            count: this.initializeFromProducts(0),
            countMax: 500, // prevents crashes rendering too many fields
            comment: null,
            shippedDate: moment(),
            serialNumbers: this.initializeFromProducts([]),
            modalNotificationIsOpen: false,
            modalNotificationText: null,
            datePickerFocused: false,
            currentlySelectedField: 0,
            currentFieldsTab: this.props.products[0],
        };
    }

    initializeFromProducts = (defaultValue) => {
        return this.props.products.reduce((obj, product) => {
            obj[product] = defaultValue;
            return obj;
        }, {});
    }

    populateCountFromCurrentOrder = (currentOrderQuantity) => {
        // old orders: only number of ravens
        // This can be deleted if all orders (including old ones) are updated to the new format
        if (typeof currentOrderQuantity === "number") {
            const obj = this.initializeFromProducts(0);
            obj["Raven"] = currentOrderQuantity;
            return obj;
        }

        // new orders: different products
        return this.props.products.reduce((obj, product) => {
            obj[product] = currentOrderQuantity[product];
            return obj;
        }, {});
    }

    populateSerialNumbersFromCurrentOrder = (currentOrderQuantity, currentOrderAssignedCount) => {
        // old orders: only number of ravens
        // This can be deleted if all orders (including old ones) are updated to the new format
        if (typeof currentOrderQuantity === "number") {
            const obj = this.initializeFromProducts([]);
            obj["Raven"] = Array(currentOrderQuantity - currentOrderAssignedCount).fill(undefined);
            return obj;
        }

        // new orders: different products
        return this.props.products.reduce((obj, product) => {
            obj[product] = Array(currentOrderQuantity[product] - currentOrderAssignedCount[product]).fill(undefined);
            return obj;
        }, {});
    }

    componentDidMount() {
        if(this.props.currentOrder)
        {
            // Populate the state with the current order
            this.setState({
                orderno: this.props.currentOrder.orderNumber,
                count: this.populateCountFromCurrentOrder(this.props.currentOrder.quantity),
                comment: this.props.currentOrder.comment,
                serialNumbers: this.populateSerialNumbersFromCurrentOrder(this.props.currentOrder.quantity, this.props.currentOrder.processed),
            });
        }
    }

    resizeSerialNumbers = (product, newLength, defaultValue) => {
        const productSerialNumbers = this.state.serialNumbers[product];

        while(newLength > productSerialNumbers.length){
            productSerialNumbers.push(defaultValue);
        }
        // only need the one length var here
        productSerialNumbers.length = newLength;
    }

    onOrderNoChange = (val) => {
        this.setState({orderno: val});
    }

    onCountChange = (product, val) => {
        var parsedCount = parseInt(val);

        if(isNaN(parsedCount)) {
                this.setState({count: {
                    ...this.state.count,
                    [product]: 0
                }});
                this.resizeSerialNumbers(product, 1, null);
        }

        if (!isNaN(parsedCount)) {
            if (this.props.currentOrder) {
                if (this.state.processed > parsedCount) {
                    parsedCount = this.state.processed
                }
            }

            if (parsedCount > this.state.countMax) {
                parsedCount = this.state.countMax
            }


            // TODO: HERE SOMEWHERE??
            this.setState({count: {
                ...this.state.count,
                [product]: parsedCount
            }});

            if (this.props.currentOrder) {

                var remaining = parsedCount - this.props.currentOrder.processed;

                if (remaining){
                    if (typeof(remaining) === "number") {
                        if (remaining >= 0) {
                            this.resizeSerialNumbers(product, parsedCount - this.props.currentOrder.processed, undefined);
                        }
                    }
                }
            }

            return;
        }

        if (this.props.currentOrder) {
            if (this.props.currentOrder.processed > 0) {
                this.setState({count: {
                    ...this.state.count,
                    [product]: this.props.currentOrder.processed
                }});
            } else {
                this.setState({count: {
                    ...this.state.count,
                    [product]: 0
                }});
                this.resizeSerialNumbers(product, 1, null);
            }
        }

    }

    onCommentChange = (val) => {
        this.setState({comment: val});
    }

    onSerialNumbersChange = (product, index, val) => {
        var serialNumbers = this.state.serialNumbers[product];
        serialNumbers[index] = val;
        this.setState({serialNumbers: {
            ...this.state.serialNumbers,
            [product]: serialNumbers
        }});
    }

    inputSelectAll = (overallIdx, event) => {
        const target = event.target;


        setTimeout(() => {
            if (!target) return;
            this.setState({
                currentlySelectedField: overallIdx
            })
            target.select();
        }, 100); // helps ensure a single click select all

    }

    onAddOrEditOrder = () => {

        console.log("Sending",this.state);

        if (this.props.currentOrder){
            // edit
            var result = this.props.onSuccess(
                this.state.orderno, this.state.count, this.state.comment, this.state.serialNumbers, this.state.shippedDate
            )

            // if edit returns 0, one of the entered serial #'s was empty
            // we return early here to avoid trigerring the onClose() and
            // state reset at the end of this fn, so that the user does not risk
            // losing all entered serial #'s
            if (result == 0) {
                var emptyFieldMsg = `Warning: One or more submitted serial numbers were empty.`
                this.setState({modalNotificationIsOpen: true, modalNotificationText: emptyFieldMsg})
                return
            }
        } else{
            // add
            this.props.onSuccess(
                this.state.orderno, this.state.count, this.state.comment 
            );
        }

        // scroll back to top after edit clicked so notification is visible
        this.props.onClose();
        this.setState({ orderno: null , count: 0, comment: null})
    }

    renderDatePicker = () => {
        return <div className="col-12">
                    <div className="row">
                        <div className="col-3">
                        Shipped Date:
                        </div>
                        <div className="col-9">
                            <SingleDatePicker
                                date={this.state.shippedDate}
                                onDateChange={ date => this.setState({ shippedDate: date })}
                                focused={this.state.datePickerFocused}
                                onFocusChange={({ focused }) => this.setState({ datePickerFocused: focused })}
                                id="shippedDate"
                                showDefaultInputIcon={false}
                                numberOfMonths={1}
                                isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())} // disable past dates
                                noBorder={true}
                                readOnly={true}
                            />
                        </div>
                    </div>
                </div>
    }


    serialFieldOnComplete = (overallIdx, value) => {
        if (overallIdx == this.state.currentlySelectedField) {
            if (typeof(value) === "string") {
                //if (value.startsWith('1RV') || value.startsWith ('2RV') || value.startsWith('1ShiftRShiftV') || value.startsWith('2ShiftRShiftV')) {
                        var nextfield = document.querySelector(
                            `input[name=serialnumberinput-${overallIdx + 1}]`
                            );
                            
                        if (nextfield !== null) {
                            //jumping to next field
                            this.setState({
                                currentFieldsTab: nextfield.getAttribute('data-product')
                            }, () => {
                                nextfield.focus();
                            });
                        }

                //}
            }
        }
    }

    renderRavenSerialNumberInputs = (product, productSerialNumbers, numberOfFieldsAlreadyGenerated) => {
        if (productSerialNumbers) {
            if (productSerialNumbers.length > 0) {
                var serialNumberInputs = productSerialNumbers.map((val, idx) =>(
                    <div className="col-12" key={idx}>
                        <div className="row">
                            <div className="col-3 text-right">
                                {`${product} serial #${idx + 1}:`}
                            </div>
                            <div className="col-9" align="right">
                                <RavenSerialNumberInput
                                    product={product}
                                    productIdx={idx}
                                    overallIdx={idx + numberOfFieldsAlreadyGenerated}
                                    type="text"
                                    setOnChange={(e) => { this.onSerialNumbersChange(product, idx, e.target.value) }}
                                    setOnFocus={(e) => this.inputSelectAll(idx + numberOfFieldsAlreadyGenerated, e)}
                                    setOnComplete={(e) => {this.serialFieldOnComplete(idx + numberOfFieldsAlreadyGenerated, e)}}
                                    serialNumbers={productSerialNumbers}
                                />
                            </div>
                        </div>
                    </div>
                ));
            }

            return (
                <div className='col-12 my-2' key={product}>
                    <div className="row">
                        <span className='pr-2'>{this.state.currentFieldsTab === product ? '▼' : '▶'}</span>
                        {`${product} Serial Numbers`} 
                    </div> 
                    <div className={this.state.currentFieldsTab === product ? 'row d-block': 'row d-none'}>
                        {serialNumberInputs}
                    </div>
                </div>
            );
        }
        // if null or 0
        return null;
    }

    renderProcessOrderFields = () => {
        if (this.props.currentOrder) {
            const ProcessOrderFields = [];
            let numberOfFieldsAlreadyGenerated = 0;
            this.props.products.forEach((product) => {
                const productSerialNumbers = this.state.serialNumbers[product];
                const productCount = this.state.count[product];
                const productAssignedCount = this.props.currentOrder.processed[product];

                // only show fields if the # Ravens on order > # Ravens already processed on order
                if (productCount && (productAssignedCount == 0)) {
                    if (productSerialNumbers) {
                        // not sure if this is necessary since it should increment
                        if (productSerialNumbers.length > 0) {
                            // only allow a certain number of fields for perf
                            if (productSerialNumbers.length > this.state.maxSerialNumbersLength) {
                                productSerialNumbers.length = this.state.maxSerialNumbersLength
                            }
                                
                            ProcessOrderFields.push(this.renderRavenSerialNumberInputs(product, productSerialNumbers, numberOfFieldsAlreadyGenerated));
                            numberOfFieldsAlreadyGenerated += productSerialNumbers.length;
                        }
                    }
                }
            });

            return ProcessOrderFields
        }

        return null;
    }

    render() {

        var orderIdReadOnly = false;

        var title = "Add Order";
        var buttonText = "Add";
        if(this.props.currentOrder)
        {
            title = "Edit Order";
            buttonText = "Edit";
            orderIdReadOnly = true;
        }

        if(this.props.data && this.props.data.account && this.props.data.account.email)
        {
            title += " for ";
            title += this.props.data.account.email;
        }

        return (
            <Dialog
                size="small"
                label={title}
                isOpen={this.props.isOpen}
                onClose={this.props.onClose}
                >

                { this.state.modalNotificationIsOpen ? <div className="row" id="modal-notification"> {this.state.modalNotificationText} </div> : null }
                <div className="row">

                    {/* Order Number */} 
                    <div className="col-3">
                        Deposco Order #:
                    </div>
                    <div className="col-9">
                        <input name="orderno"
                            readOnly={orderIdReadOnly}
                            type="text"
                            onChange={(e) => { this.onOrderNoChange(e.target.value) }}
                            value={this.state.orderno}
                        />
                    </div>
                    
                    {/* comment */} 
                    <div className="col-3">
                        Comment:
                    </div>
                    <div className="col-9">
                        <input name="comment"
                            type="text"
                            onChange={(e) => { this.onCommentChange(e.target.value) }}
                            value={this.state.comment}
                        />
                    </div>

                    {/* Date Picker */}
                    {this.props.currentOrder && this.renderDatePicker()}

                    {/* Number of orders */}
                    <div className="col-3">Number of:</div>
                    <div className="col-9">
                        <div className='row'>   
                            {
                                this.props.products.map((product, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <div className='col-1'>{product}</div>
                                        <div  className="col-2">
                                            <input name={`${product}-count`}
                                                type="text"
                                                onChange={(e) => { this.onCountChange(product, e.target.value) }}
                                                onFocus={(e) => this.setState({currentFieldsTab: product})}
                                                value={this.state.count[product]}
                                            />
                                        </div>
                                        </Fragment>
                                    );
                                })
                            }
                        </div>
                    </div>

                    {/* Serial Numbers */}
                    {this.renderProcessOrderFields()}


                    {/* Buttons */}
                    <div className="col-12">
                        <div align="right">
                            <button onClick={this.props.onClose}>Cancel</button>
                            <button onClick={this.onAddOrEditOrder}>{buttonText}</button>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }
}
