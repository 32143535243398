

if(process.env.REACT_APP_USE_FAKE_FETCH === '1'){

    console.warn('Using fake fetch from ./data/api/rcApi/fakeFetchRequestHandler.js');

    const { fetch: originalFetch } = window;

    const endpointsToReplaceWithFakeData = [
        "/beacons?",
        "/accounts?",
        "/item-types",
    ]


    const BEACONS_DATA = []
    const ACCOUNTS_DATA = []
    const ITEM_TYPES_DATA = []
    const ORDERS_DATA = []

    function initDataLists(){

        function randomDate(start, end) {
            return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime())).getTime();
        }

        // Beacons
        for (let i = 1; i < 100; i++) {
            BEACONS_DATA.push({
                beacon_uuid: i,
                bt_mac: i < 10 ? `0${i}:00:${i}0:${i}${i}:00:0${i}` : `${i}:00:${i}:${i}:00:${i}`,
                make: i % 4 === 0 ? 'Momentum' : 'Minew',
                model: i % 6 === 0 ? 'CrewID' : 'E9',
                asset_uuid: i,
                asset_name: i % 3 === 0 ? 'Bobcat no ' + i : i % 4 === 0 ? 'Concrete Mixer no ' + i : 'Truck no ' + i,
                stage: i % 5 === 0 ? 'test' : i % 7 === 0 ? 'beta3' : 'dev',
                account: 'mohsen@ravenconnected.com',
                longitude: -75.6932796,
                latitude: 45.4015076,
                ts: randomDate(new Date(2024, 0, 1), new Date()),
                visibility: i % 9 === 0 ? false : true,
            })
        }

        delete BEACONS_DATA[2].asset_uuid;
        delete BEACONS_DATA[2].asset_name;
        delete BEACONS_DATA[2].ts;
        
        delete BEACONS_DATA[5].asset_uuid;
        delete BEACONS_DATA[5].asset_name;
        delete BEACONS_DATA[5].ts;

        delete BEACONS_DATA[8].asset_uuid;
        delete BEACONS_DATA[8].asset_name;
        delete BEACONS_DATA[8].ts;

        delete BEACONS_DATA[10].asset_uuid;
        delete BEACONS_DATA[10].asset_name;
        delete BEACONS_DATA[10].ts;

        delete BEACONS_DATA[11].asset_uuid;
        delete BEACONS_DATA[11].asset_name;
        delete BEACONS_DATA[11].ts;

        // Accounts
        ACCOUNTS_DATA.push(...[
            {
                accountId: 1,
                email: 'mohsen@ravenconnected.com',
                type: 'FLEET',
                stage: 'dev'
            },
            {
                accountId: 2,
                email: 'rob@ravenconnected.com',
                type: 'FLEET',
                stage: 'dev'
            },
            {
                accountId: 3,
                email: 'marc@ravenconnected.com',
                type: 'User',
                stage: 'dev'
            },{
                accountId: 4,
                email: 'mohsen@ravenconnected.com',
                type: 'FLEET',
                stage: 'test'
            },
            {
                accountId: 5,
                email: 'travis@ravenconnected.com',
                type: 'FLEET',
                stage: 'test'
            },
            {
                accountId: 6,
                email: 'laura@ravenconnected.com',
                type: 'User',
                stage: 'test'
            }
        ]);

        // Item Types
        ITEM_TYPES_DATA.push(...[
            {
                "id": "RAVEN",
                "name": "Raven Classic",
                "category": "DEVICE",
                'type': 'RAVEN'
            },
            {
                "id": "RAVEN_PLUS",
                "name": "Raven+",
                "category": "DEVICE",
                'type': 'RAVEN'
            },
            {
                "id": "SD_CARD_64G",
                "name": "SD Card: 64GB",
                "category": "ACCESSORY",
                'type': 'SIMPLE'
            },
            {
                "id": "SD_CARD_256G",
                "name": "SD Card: 256GB",
                "category": "ACCESSORY",
                'type': 'SIMPLE',
                'included': {
                    'RAVEN_GT': 1,
                }
            },
            {
                "id": "BEACON",
                "name": "Beacon",
                "category": "ACCESSORY",
                'type': 'BEACON'
            },
            {
                "id": "OBD2_CABLE_6FT",
                "name": "6FT OBDII Cable",
                "category": "ACCESSORY",
                'type': 'SIMPLE'
            },
            {
                "id": "Y-CABLE",
                "name": "Y Cable",
                "category": "ACCESSORY",
                'type': 'SIMPLE',
                'included': {
                    'RAVEN_GT': 2,
                    'RAVEN': 1
                }
            }
        ]);

        // Orders
    }

    window.fetch = async (...args) => {
        let [resource, config] = args;

        if(!endpointsToReplaceWithFakeData.some(endpoint => resource.includes(endpoint))){
            return await originalFetch(resource, config);
        }

        console.log('Fake fetch', resource, config);

        const stage = /api\.([^\.]*)\.(?:klashwerks.com|telus.ravenconnected.com)/g.exec(resource)[1];
        const method = config.method;
        const response = {
            headers: { get: () => 'application/json'},
            status: 200,
            ok: true,
        }

        

        if(resource.includes('/beacons')){
            if(method === 'GET'){
                const account = /\?account=(.*)/g.exec(resource)[1];


                const filteredList = JSON.parse(JSON.stringify(BEACONS_DATA.filter((beacon) => beacon.stage === stage && beacon.account === account)));
                
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        response.json = () => filteredList;
                        return resolve(response);
                        //return reject(new Error("…")); // ignored
                    }, 3000);
                });
            }

            if(method === 'DELETE'){
                const beacon_uuid = /\&id=(.*)/g.exec(resource)[1];

                const index = BEACONS_DATA.findIndex(beacon => beacon.beacon_uuid == beacon_uuid);
                const deletedBeacon = BEACONS_DATA[index];
                console.log('Deleting beacon', beacon_uuid, index, BEACONS_DATA, deletedBeacon);
                if(index !== -1){
                    BEACONS_DATA.splice(index, 1);
                }

                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        response.json = () => deletedBeacon;
                        return resolve(response);
                        //return reject(new Error("…")); // ignored
                    }, 1000);
                });
            }

            if(method === 'POST'){
                const account = /\?account=(.*)/g.exec(resource)[1];
                const data = JSON.parse(config.body);

                const newBeacon = {
                    beacon_uuid: BEACONS_DATA.length + 1,
                    stage: stage,
                    account: account,
                    ts: new Date().getTime(),
                    visibility: true,
                    bt_mac: data.btMac,
                    make: data.make,
                    model: data.model,
                }

                BEACONS_DATA.push(newBeacon);

                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        response.json = () => newBeacon;
                        return resolve(response);
                        //return reject(new Error("…")); // ignored
                    }, 1000);
                });
            }
        }

        if(resource.includes('/accounts?')){
            
            const keyword = /\?keyword=(.*)/g.exec(resource)[1];

            const filteredList = ACCOUNTS_DATA.filter((account) => account.stage === stage && account.email.includes(keyword));
            
            console.log('Accounts Filtered list', keyword, stage, ACCOUNTS_DATA, filteredList)

            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    response.json = () => filteredList;
                    resolve(response);
                    //return reject(new Error("…")); // ignored
                }, 1000);
            });
        }

        if(resource.includes('/item-types')){

            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    response.json = () => ITEM_TYPES_DATA;
                    return resolve(response);
                    //return reject(new Error("…")); // ignored
                }, 3000);
            });
        }

        return [];
    };


    initDataLists();
}else{
    console.warn('Using real fetch');
}