// Stateless react component to render a list of links to be clicked on
// Passes in the title, list and the callback as props

import React, { Component } from 'react';
import ReactTable from 'react-table';
import Events from '../../../common/events';
import "react-table/react-table.css";

import globalconfig from '../../../common/config';

import supporttoolGeoIntegrationIconGtUnpaired from './images/support-tool-geo-integration-icon_gt_unpaired.png';
import supporttoolGeoIntegrationIconGt from './images/support-tool-geo-integration-icon_gt.png';
import supporttoolGeoIntegrationIconGts from './images/support-tool-geo-integration-icon_gts.png';

import ProgressOverlay from '../../../common/ProgressOverlay';

const TABLE_PAGE_SIZE = 100;

export default class FeatureListing extends Component {

    constructor () {
        super();

        this.state = {
            tablePageIndex: 0, // used as a flag more than strictly "controlling" the component (i.e. due to feature list instability, we should not "control" and definitely not "reset" page indexes on feature list changes)
            geotabIntegrationsForRavenUUIDs: {},
            geotabIntegrationsForRavenUUIDsPending: true
        };
    };

    onItemClick = (e, item) => {
        e.preventDefault();

        // Don't need to click the feature here as dispatching the event below will trigger
        // this.props.onFeatureClick(item.props.original);

        document.dispatchEvent(new CustomEvent(Events.RAVEN_SELECT_EVENT,
            { detail: {
                message: 'Selecting Raven',
                ravenid: item.props.original.item['Id'],
                ravenunitid: item.props.original.item['Raven']['Unit Id'],
                ravenuuid: item.props.original.item['Raven']['Raven UUID'],
                geometry: item.props.original.geometry
            } })
        );

    }

    onItemMouseOver = (e, item) => {
        e.preventDefault();

        document.dispatchEvent(new CustomEvent(Events.RAVEN_HIGHLIGHT_EVENT,
            { detail: {
                message: 'Highlight Raven',
                ravenid: item.props.original.item['Id'],
                ravenunitid: item.props.original.item['Raven']['Unit Id'],
                ravenuuid: item.props.original.item['Raven']['Raven UUID'],
                geometry: item.props.original.geometry
            } })
        );
    }

    compareFeatureListForChanges = (previousPropsFeatureList, currentPropsFeatureList) => {
        if (previousPropsFeatureList.length !== currentPropsFeatureList.length) {
            // no-brainer case where the feature list has obviously changed
            return true;
        } else {
            // trickier situation where the feature list is the same length, but the features themselves could be different
            return !currentPropsFeatureList.every((feature, index) => {

                const ravenUUIDFromCurrentProps = feature.item && feature.item.Raven && feature.item.Raven["Raven UUID"] ? feature.item.Raven["Raven UUID"] : "";
                const previousFeatureAtIndex = previousPropsFeatureList[index];
                const ravenUUIDFromPreviousProps = previousFeatureAtIndex.item && previousFeatureAtIndex.item.Raven && previousFeatureAtIndex.item.Raven["Raven UUID"] ? previousFeatureAtIndex.item.Raven["Raven UUID"] : "";

                return ravenUUIDFromCurrentProps === ravenUUIDFromPreviousProps;
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const previousPropsFeatureList = prevProps.featureList ? prevProps.featureList : [];
        const currentPropsFeatureList = this.props.featureList ? this.props.featureList : [];

        const previousStateTablePageIndex = prevState.tablePageIndex;
        const currentStateTablePageIndex = this.state.tablePageIndex;

        const featureListHasChanged = this.compareFeatureListForChanges(previousPropsFeatureList, currentPropsFeatureList);

        const tablePageHasChanged = previousStateTablePageIndex !== currentStateTablePageIndex;

        if (featureListHasChanged || tablePageHasChanged) {

            /* pre-existing issue can cause the feature list to change simply by clicking on a feature (i.e. click seems to cause a re-render, definitely causes more data to load, data is sorted on render by reporting time (by default) and that data has changed so the feature list "changes")
            if (featureListHasChanged) {
                this.setState({tablePageIndex: 0});
            }
            */
            this.setState({
                //geotabIntegrationsForRavenUUIDs: {}, // the comment above (i.e. feature list instability) forces me to treat geotabIntegrationsForRavenUUIDs like a cache (don't clear it) and buffer he request with 100s more raven ids to reduce chances of missing data
                geotabIntegrationsForRavenUUIDsPending: true
            });

            const currentPropsFeatureListRavenUUIDs = [];
            
            const featureListOffset = currentStateTablePageIndex * TABLE_PAGE_SIZE;
            const featureListMaxIndexPlus1 = featureListOffset + (TABLE_PAGE_SIZE * 2); // multiplying TABLE_PAGE_SIZE * 2 as a bargain basement "buffer" to better handle feature list instability

            for (let index = featureListOffset; index < currentPropsFeatureList.length && index < featureListMaxIndexPlus1; index += 1) {
                const feature = currentPropsFeatureList[index];
                const ravenUUID = feature.item && feature.item.Raven && feature.item.Raven["Raven UUID"] ? feature.item.Raven["Raven UUID"] : "";
                if (ravenUUID) {
                    currentPropsFeatureListRavenUUIDs.push(ravenUUID);
                }
            }

            if (currentPropsFeatureListRavenUUIDs.length) {
                this.props.ravenDataStore.getGeotabIntegrationsForRavenUUIDs(this.props.stage, currentPropsFeatureListRavenUUIDs)
                .then(
                    (response) => {
                        const ravensGeotabIntegrationDetails = response && response.results && response.results.length ? response.results : [];

                        //const geotabIntegrationsForRavenUUIDs = {}; // clear "pending" undefined with at least an empty object
                        const geotabIntegrationsForRavenUUIDs = this.state.geotabIntegrationsForRavenUUIDs ? this.state.geotabIntegrationsForRavenUUIDs : {}; // keep "caching" values to try to handle feature list instability

                        if (ravensGeotabIntegrationDetails.length) {

                            ravensGeotabIntegrationDetails.forEach( (integration) => {
                            
                                const integrationRavenUUID = integration.ravenUuid;
                                
                                if (!integrationRavenUUID) return;

                                if (!integration.geotab) return;

                                geotabIntegrationsForRavenUUIDs[integrationRavenUUID] = integration;
                            });
                        };

                        this.setState({
                            geotabIntegrationsForRavenUUIDs: geotabIntegrationsForRavenUUIDs,
                            geotabIntegrationsForRavenUUIDsPending: false
                        });
                    },
                    (error) => {

                    }
                );
            }
        }
    }

    render() {

        var col3accessor = 'item.';
        col3accessor += this.props.displayVariable.section ?  this.props.displayVariable.section + '.' : '';
        col3accessor += this.props.displayVariable.name;

        var col3cell = props => { return <a href="#!" 
            onMouseOver={(e) => { this.onItemMouseOver(e, {props})}} 
            onClick={(e) => { this.onItemClick(e, {props})}}>{this.props.displayVariable.renderMethod ? this.props.displayVariable.renderMethod(props.value) : props.value }</a> };

        var columns = [];

        if (globalconfig.features.ravens.vendorDetailsPreferred) { // OEM/Vendor 
            columns.push({
                Header: 'ID',
                accessor: (d) => { // "NOTE If your data has a field/key with a dot (.) you will need to supply a custom accessor." (https://www.npmjs.com/package/react-table-v6#columns)
                    if (d.item.Raven['Raven External Id']) {//d.item.Raven.hasOwnProperty('Raven External Id')) {
                        return d.item.Raven['Raven External Id'];
                    }
                    if (d.item.Raven.hasOwnProperty('Enclosure Serial No.')) { // graceful fallback (no error message, supports other vendors who do not have subscriber ids)
                        return d.item.Raven['Enclosure Serial No.'];
                    }
                    return d.item.Raven['Unit Id'];
                }, //['item','Raven','Enclosure Serial No.'],
                id: 'Id',
                width: 135,
                Cell: props => {
                    const ravenUUID = props.original && props.original.item && props.original.item.Raven && props.original.item.Raven["Raven UUID"] ? props.original.item.Raven["Raven UUID"] : "";
                    return (
                        <div className="feature-item">
                            <div className="integration-decoration">
                                {this.state.geotabIntegrationsForRavenUUIDs[ravenUUID] ?
                                    <>
                                    {this.state.geotabIntegrationsForRavenUUIDs[ravenUUID].geotab.mode === "STANDALONE" ?
                                        <img src={supporttoolGeoIntegrationIconGts} />
                                    :
                                        <>
                                        {this.state.geotabIntegrationsForRavenUUIDs[ravenUUID].geotab.mode === "PAIRED" ?
                                            <>
                                                {this.state.geotabIntegrationsForRavenUUIDs[ravenUUID].geotab.pairedAsset ?
                                                    <img src={supporttoolGeoIntegrationIconGt} />
                                                :
                                                    <img src={supporttoolGeoIntegrationIconGtUnpaired} />
                                                }
                                                
                                            </>
                                        :
                                            null // for now, show nothing (consider an an indicator that the integration mode could not be determined)
                                        }
                                        </>
                                    }
                                    </>
                                :
                                    <>
                                    {this.state.geotabIntegrationsForRavenUUIDsPending ? // if load completed
                                        <ProgressOverlay />
                                    :
                                        null
                                    }
                                    </>
                                }
                            </div>
                            <a className="integration-link" href="#!" onMouseOver={(e) => { this.onItemMouseOver(e, {props})}} onClick={(e) => { this.onItemClick(e, {props})}}>
                                {props.value}
                            </a>
                        </div>
                    );
                }
            });
        } else {
            columns.push({
                Header: 'ID',
                accessor: 'item.Raven.Unit Id',
                Cell: props => <a href="#!" 
                    onMouseOver={(e) => { this.onItemMouseOver(e, {props})}} 
                    onClick={(e) => { this.onItemClick(e, {props})}}>{props.value}</a>
            });
        }

        if (globalconfig.features.ravens.vendorDetailsPreferred) { // OEM/Vendor 
            columns.push({
                Header: 'Account',
                accessor: (d) => { // "NOTE If your data has a field/key with a dot (.) you will need to supply a custom accessor." (https://www.npmjs.com/package/react-table-v6#columns)
                    if (d.item.Account.hasOwnProperty('Account Name')) {
                        return d.item.Account['Account Name'];
                    }
                    return d.item.Raven['Unit Id'];
                }, //['item','Raven','Enclosure Serial No.'],
                id: 'Account',
                Cell: props => <a title={props.value} href="#!" 
                    onMouseOver={(e) => { this.onItemMouseOver(e, {props})}} 
                    onClick={(e) => { this.onItemClick(e, {props})}}>{props.value}</a>
            });
        } else {    
            columns.push({
                Header: 'Email',
                accessor: 'item.Account.Email',
                Cell: props => <a title={props.value} href="#!" 
                    onMouseOver={(e) => { this.onItemMouseOver(e, {props})}} 
                    onClick={(e) => { this.onItemClick(e, {props})}}>{props.value}</a>
            });
        }
        columns.push({
            Header: this.props.displayVariable.label,
            accessor: col3accessor,
            id: 'default-sort-column-id', // https://spectrum.chat/react-table/general/default-multiple-sorting~665cf8be-5054-4126-ac49-1f44a1895b01
            width: 80,
            Cell: col3cell,
                sortMethod: this.props.displayVariable.sortMethod
        });

        var paginate = this.props.featureList.length > TABLE_PAGE_SIZE;

        let sortDesc = false; // see also displayVariableTypes' current sortMethod

        if (col3accessor === 'item.Last Update') {
            sortDesc = true; // effecively ASCENDING for this data set and displayVariableTypes' current sortMethod
        }

        return (
            <div id="featureListing" className="blank-page">
                    <ReactTable
                        className="-striped"
                        data={this.props.featureList}
                        showPagination={paginate}
                        showPageSizeOptions={false}
                        showPageJump={false}
                        pageSize={paginate ? TABLE_PAGE_SIZE : this.props.featureList.length }
                        defaultSorted={[
                            { id: 'default-sort-column-id', desc: sortDesc }
                        ]}
                        style={{ height: "70vh" }}
                        noDataText="No Ravens in View"
                        columns={columns}
                        //page={this.state.tablePageIndex} data is effectively too unstable to "control" current page
                        onPageChange={(pageIndex) => {this.setState({tablePageIndex: pageIndex})}}
                    />
            </div>
        );
    }
}
