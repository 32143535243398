import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import StageSelector from '../widgets/StageSelector';

import { MAIN_FILTER_TYPES } from './../ordersPage';

export default class Header extends React.PureComponent {

    componentDidMount() {
        console.log(window.location.pathname);
    }

    render() {
        const activeOrdersClass = this.props.mainFilter !== MAIN_FILTER_TYPES.SHOW_ONLY_COMPLETED ? "active" : null;        
        const orderHistoryClass = this.props.mainFilter === MAIN_FILTER_TYPES.SHOW_ONLY_COMPLETED ? "active" : null;

        return (
            <header>
                <Link to="/" className="logo"></Link>
                <div className="controls">
                    <StageSelector
                        ravenStages = {this.props.ravenStages}
                        stage = {this.props.stage}
                        onRavenStageChange = {this.props.onRavenStageChange}
                    />
                </div>
                <nav>
                    <button onClick={() => {this.props.onMainFilterClick(MAIN_FILTER_TYPES.SHOW_ALL_UNFULFILLED)}} className={activeOrdersClass}>Active Orders</button>
                    <button onClick={() => {this.props.onMainFilterClick(MAIN_FILTER_TYPES.SHOW_ONLY_COMPLETED)}} className={orderHistoryClass}>History</button>
                    {/*<button onClick={this.props.onClickAddInventory}>New Inventory</button>*/}
                </nav>
            </header>
        );
    }
}


Header.propTypes = {
    ravenStages: PropTypes.array.isRequired,
    stage: PropTypes.string.isRequired,
    onRavenStageChange: PropTypes.func.isRequired,
    mainFilter: PropTypes.string,
    onMainFilterClick: PropTypes.func.isRequired,
    onClickAddInventory: PropTypes.func.isRequired
};