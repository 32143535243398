import React from 'react';
import PropTypes from 'prop-types';
import supporttoolIconInfoWhite from './images/supporttool-icon-info-white.png';
import { ACCOUNT_INTEGRATIONS_TYPES } from '../../../orders/OrderForm';
//import { ORDER_TYPES } from '../../../orders/OrderForm';
import { ActivityIndicator } from '../Diagnostics/diagnostics';
import ProgressOverlay from '../../../../common/ProgressOverlay';

export const DEVICE_MODES = {
    GT: "GT",
    GT_UNPAIRED: "GT_UNPAIRED",
    GTS: "GTS"
};

export default class GeotabIntegration extends React.PureComponent {

    static propTypes = {
        stage: PropTypes.string.isRequired,
        dataStore: PropTypes.object.isRequired, // for requesting refresh for missing details on app header sort displayVariable changes
        item: PropTypes.object.isRequired,
    }

    constructor(props) {
        super(props);

        this.state = {
            ravenGeotabIntegrationType: ACCOUNT_INTEGRATIONS_TYPES.NONE,
            ravenGeotabIntegrationFromCloudPending: true,
            ravenGeotabIntegrationFromCloud: undefined,
            ravenGeotabIntegrationFromCloudErrorMessage: undefined,
            ravenGeotabIntegrationFromCloudSuccessMessage: undefined,

            ravenGeotabModeEdit: undefined
        };

    }

    componentDidMount() {
        this.getRavenGeotabIntegration();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const previousPropsItem = prevProps.item;
        const currentPropsItem = this.props.item;

        if (previousPropsItem.Id !== currentPropsItem.Id) {
            this.setState({
                ravenGeotabIntegrationFromCloudErrorMessage: undefined,
                ravenGeotabIntegrationFromCloudSuccessMessage: undefined
            });
            this.getRavenGeotabIntegration();
        }

    }

    getRavenGeotabIntegration = () => {

        if (!this.props.stage) {
            console.error("GeotabIntegration getRavenGeotabIntegration missing required prop stage");
            return;
        }

        const ravenUUID = this.props.item && this.props.item["Raven"] ? this.props.item["Raven"]["Raven UUID"] : undefined;

        if (!ravenUUID) {
            console.error("GeotabIntegration getRavenGeotabIntegration missing require prop item or item's raven uuid is not found.");
            return;
        };

        this.setState({
            ravenGeotabIntegrationFromCloudPending: true
        });

        const ravenUUIDs = [ravenUUID];

        this.props.dataStore.getGeotabIntegrationsForRavenUUIDs(this.props.stage, ravenUUIDs)
        .then(
            (response) => {
                this.setState({
                    ravenGeotabIntegrationFromCloudPending: false
                });

                const ravenGeotabIntegrationFromCloud = response && response.results && response.results.length ? response.results[0] : undefined;

                if (ravenGeotabIntegrationFromCloud) {

                    this.setState({
                        ravenGeotabIntegrationType: ACCOUNT_INTEGRATIONS_TYPES.GEOTAB,
                        ravenGeotabIntegrationFromCloud: ravenGeotabIntegrationFromCloud, // will be undefined if response is 404 (integration not found)
                    });

                    return;
                }
                this.setState({
                    ravenGeotabIntegrationFromCloudPending: false,
                    ravenGeotabIntegrationFromCloud: undefined
                });
            },
            (error) => {
                this.setState({
                    ravenGeotabIntegrationFromCloudPending: false,
                    ravenGeotabIntegrationFromCloud: undefined,
                    ravenGeotabIntegrationFromCloudErrorMessage: "An error occurred while loading account geotab integration"
                });
                return;
            }
        );
    };

    onInputChange = (event, geotabPairedGo9) => {

        if (geotabPairedGo9) {
            this.setState({
                ravenGeotabIntegrationFromCloudErrorMessage: "A GT Raven must be unpaired in MyGeotab's 'Manage Ravens' add-in page before changing mode to GTS."
            });
            return;
        }
        this.setState({
            ravenGeotabIntegrationFromCloudErrorMessage: undefined
        });

        let ravenGeotabModeFromCloud = undefined;

        if (this.state.ravenGeotabIntegrationFromCloud) {
            const ravenGeotabIntegrationDetails = this.state.ravenGeotabIntegrationFromCloud.geotab;
            switch (ravenGeotabIntegrationDetails.mode) {
                case "PAIRED":
                    ravenGeotabModeFromCloud = DEVICE_MODES.GT; // OR DEVICE_MODES.GT_UNPAIRED (collapsing to DEVICE_MODES.GT)
                    break;
                case "STANDALONE":
                    ravenGeotabModeFromCloud = DEVICE_MODES.GTS;
                    break;
                default:
                    break;
            };
        };

        let ravenGeotabModeEdit = event.target.value;

        if (ravenGeotabModeEdit === ravenGeotabModeFromCloud) { // i.e. user is reverting the change manually
            ravenGeotabModeEdit = undefined; // not an edit (i.e. clear the edit in state to undefined)
        }

        this.setState({
            ravenGeotabModeEdit: ravenGeotabModeEdit
        });
    };

    onActionSubmit = (event) => {
        
        const ravenSerial = this.state.ravenGeotabIntegrationFromCloud && this.state.ravenGeotabIntegrationFromCloud.ravenSerial ? this.state.ravenGeotabIntegrationFromCloud.ravenSerial : undefined;

        let mode = undefined;
        switch (this.state.ravenGeotabModeEdit) {
            case DEVICE_MODES.GT:
            case DEVICE_MODES.GT_UNPAIRED:
                mode = "PAIRED";
                break;
            case DEVICE_MODES.GTS:
                mode = "STANDALONE";
                break;
            default:
                break;
        }

        if (!ravenSerial) {
            console.error("Raven serial number could not be determined");
            return;
        }

        if (!mode) {
            console.error("Requested Raven mode could not be determined");
            return;
        }

        this.setState({
            ravenGeotabIntegrationFromCloudPending: true,
            ravenGeotabIntegrationFromCloudSuccessMessage: undefined,
            ravenGeotabIntegrationFromCloudErrorMessage: undefined
        });

        const accountExternalId = this.props.item.Account['Account Name'];

        this.props.dataStore.setRavenGeotabIntegrationMode(this.props.stage, accountExternalId, [ravenSerial], mode)
        .then(
            () => {
                this.getRavenGeotabIntegration(); // this will also clear ravenGeotabIntegrationFromCloudPending
                const requestedMode = mode === "PAIRED" ? "GT" : "GTS";
                this.setState({
                    ravenGeotabModeEdit: undefined,
                    ravenGeotabIntegrationFromCloudSuccessMessage: "Raven mode successfully changed to " + requestedMode
                });        
            },
            (error) => {
                this.setState({
                    ravenGeotabIntegrationFromCloudPending: false,
                    ravenGeotabIntegrationFromCloudErrorMessage: error.message
                });        
                console.error(error);
            }
        );
    };

    render () {

        let geotabDatabaseName = undefined;
        let ravenGeotabMode = undefined;
        let ravenGeotabSerialNumber = undefined;
        let geotabPairedGo9 = undefined;

        if (this.state.ravenGeotabIntegrationFromCloud) {

            const ravenGeotabIntegrationDetails = this.state.ravenGeotabIntegrationFromCloud.geotab;

            geotabDatabaseName = ravenGeotabIntegrationDetails.databaseName;

            ravenGeotabSerialNumber = ravenGeotabIntegrationDetails.serial;

            geotabPairedGo9 = ravenGeotabIntegrationDetails.pairedAsset && ravenGeotabIntegrationDetails.pairedAsset.serial !== ravenGeotabSerialNumber? ravenGeotabIntegrationDetails.pairedAsset.serial : undefined;

            switch (ravenGeotabIntegrationDetails.mode) {
                case "PAIRED":
                    if (geotabPairedGo9) {
                        ravenGeotabMode = DEVICE_MODES.GT;
                    } else {
                        ravenGeotabMode = DEVICE_MODES.GT;//DEVICE_MODES.GT_UNPAIRED;
                    }
                    break;
                case "STANDALONE":
                    ravenGeotabMode = DEVICE_MODES.GTS;
                    break;
                default:
                    break;
            }

            if (this.state.ravenGeotabModeEdit) {
                ravenGeotabMode = this.state.ravenGeotabModeEdit
            }
        }

        if (this.state.ravenGeotabIntegrationFromCloudPending === false && this.state.ravenGeotabIntegrationFromCloud === undefined) {
            return (
                <section className="panel-geotab-integration">
                    <header>
                        Geotab Integration
                    </header>
                    <div>
                        <label>None</label>
                    </div>
                </section>
            );
        }



        return (
            <section className="panel-geotab-integration">
                <header>
                    Geotab Integration
                </header>
                <div>
                    <label>Database name: {geotabDatabaseName}</label>
                </div>
                <div>
                    <label>Raven Type<span className="required-badge">•</span></label>
                    <div className="order-form-radio-wrapper">
                        <input autoComplete="off" type="radio" name="orderType" checked={ravenGeotabMode === DEVICE_MODES.GT} value={DEVICE_MODES.GT} id={DEVICE_MODES.GT} onChange={this.onInputChange} />
                        <label htmlFor={DEVICE_MODES.GT}>GT</label>
                    </div>
                    <div className="order-form-radio-wrapper">
                        <input autoComplete="off" type="radio" name="orderType" checked={ravenGeotabMode === DEVICE_MODES.GTS} value={DEVICE_MODES.GTS} id={DEVICE_MODES.GTS} onChange={(event) => {this.onInputChange(event, geotabPairedGo9)}} />
                        <label htmlFor={DEVICE_MODES.GTS}>GTS</label>
                    </div>
                    <div className="diagnostics">{/* using a diagnostics class wrapper to leverage Diagnostics panel CSS */}
                        <div className="info-icon">
                            <img src={supporttoolIconInfoWhite} />
                            <div className="tooltip-large">
                                <span>
                                    {"The current mode for this Raven's integration in the account. GT ravens can be paired with Go9s. GTS ravens are standalone."}
                                </span>
                            </div>
                        </div>
                    </div>
                    {this.state.ravenGeotabIntegrationFromCloudPending ? <ProgressOverlay /> : null}
                </div>
                {ravenGeotabSerialNumber ?
                    <div>
                        <label>Geotab S/N: {ravenGeotabSerialNumber}</label>
                    </div>
                :
                    null
                }
                {geotabPairedGo9 ?
                    <div>
                        <label style={{color: "orange"}}>Paired with: {geotabPairedGo9}</label>
                    </div>
                :
                    null
                }
                <div className="error-message">{this.state.ravenGeotabIntegrationFromCloudErrorMessage}</div>
                <div className="success-message">{this.state.ravenGeotabIntegrationFromCloudSuccessMessage}</div>
                <div className="form-buttons">
                    <button disabled={this.state.ravenGeotabModeEdit === undefined || geotabPairedGo9} className="btn actions-submit" onClick={this.onActionSubmit} >Apply</button>
                </div>
            </section>
        )
    }

}
