import React, { Component } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import MyLoadable from '../common/MyLoadable';

import globalconfig from "../common/config";

/*
const LoadableAccountPage = MyLoadable({ loader: () => import('../pages/account/accountPage') });
const LoadableRavenPage = MyLoadable({ loader: () => import('../pages/raven/ravenPage') });
const LoadableToolPage = MyLoadable({ loader: () => import('../pages/tool/toolPage') });
*/
import LoadableAccountPage from '../pages/account/accountPage';
import LoadableRavenPage from '../pages/raven/ravenPage';
import LoadableOrdersPage from '../pages/orders/ordersPage';
import LoadableToolPage from '../pages/tool/toolPage';
import LoadableBeaconsPage from '../pages/beacons/beaconsPage';

export default class RootPage extends Component {
    constructor() {
        super();

        this.state = {
            stages: globalconfig.ravenStages ? globalconfig.ravenStages : [],
        };
    }

    render () {

        // FIXME - Ensure authenticated, otherwise redirect

        let ordersFulfillmentEnabled = false;
        let accountsManagementEnabled = false;
        let beaconsManagementEnabled = false;
        let toolsEnabled = false;

        if (globalconfig.features) {
            if (globalconfig.features.orders) {
                ordersFulfillmentEnabled = globalconfig.features.orders.fulfillmentEnabled;
            }
            if (globalconfig.features.accounts) {
                accountsManagementEnabled = globalconfig.features.accounts.managementEnabled;
            }
            if (globalconfig.features.beacons) {
                beaconsManagementEnabled = globalconfig.features.beacons.managementEnabled;
            }
            if (globalconfig.features.tools) {
                toolsEnabled = globalconfig.features.tools.enabled;
            }
        }

        return (
    
        <div id="root">

             <Switch>
                 <Route exact path="/" render={(props) => (
                    <section className="landing-page-authenticated">
                        <header>
                            <Link to="/" className="logo"></Link>
                        </header>
                        { this.state.stages.length === 0 ?
                            <h1>Permission Denied</h1> :
                            <nav>
                                {accountsManagementEnabled ?
                                    <Link to={"/account/" + this.state.stages[0] }>Accounts</Link>
                                :
                                    null
                                }
                                <Link to={"/raven/" + this.state.stages[0] }>Ravens</Link>
                                {beaconsManagementEnabled ?
                                    <Link to={"/beacons/" + this.state.stages[0] }>Beacons</Link>
                                :
                                    null
                                }
                                {ordersFulfillmentEnabled ?
                                    <Link to={"/orders/" + this.state.stages[0] }>Orders</Link>
                                :
                                    null
                                }
                                {toolsEnabled ?
                                    <Link to={"/tool/" + this.state.stages[0] }>Tools</Link>
                                :
                                    <Link to={"/signout"}>Sign Out</Link>
                                }
                            </nav>
                        }
                    </section>
                 )} />

                {accountsManagementEnabled ?
                    <Route path="/account/:stage" render={(props) => (
                        <LoadableAccountPage {...this.props} onLogin={this.onLogin} {...props} {...this.state} />
                    )} />
                :
                    null
                }

                 <Route path="/raven/:stage/:ravenUuid?" render={(props) => (
                     <LoadableRavenPage {...this.props} onLogin={this.onLogin} {...props} {...this.state} />
                 )} />

                {beaconsManagementEnabled ?
                    <Route path="/beacons/:stage/:id?/:email?" render={(props) => (
                        <LoadableBeaconsPage {...this.props} onLogin={this.onLogin} {...props} {...this.state} />
                    )} />
                :
                    null
                }

                {ordersFulfillmentEnabled ?
                    <Route path="/orders/:stage" render={(props) => (
                        <LoadableOrdersPage {...this.props} onLogin={this.onLogin} {...props} {...this.state} />
                    )} />
                :
                    null
                }

                {toolsEnabled ?
                    <Route path="/tool/:stage" render={(props) => (
                        <LoadableToolPage {...this.props} onLogin={this.onLogin} {...props} {...this.state} />
                    )} />
                :
                    null
                }

                <Route path="/signout" render={() => {
                    window.localStorage.clear()
                    window.location.href = window.location.origin;
                    return null;
                }} />

            </Switch>
        </div>
        );

    }
}
