import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import Select from 'react-select';
import Clipboard from 'clipboard';

import Dialog from '../dialog';
import Events from '../../../../common/events';
import recurlyOptions from './recurlyOptions';
import globalconfig from '../../../../common/config';
import CognitoUtil from '../../../../aws/cognito/cognitoUtil';
import RavenDataStore from '../../../../common/ravenDataStore';



export class UpdateUserModal extends Component {

    constructor() {
    
        super();

        this.state = {
            ravenData: { accounts: [] },
            channel: '',
            channelid: -1,
            email: '',
            passwd: null,
            name: '',
            isOpen: false,
        };

        this.localState = {}

        this.dataStore = new RavenDataStore(this.dataChangeCallback);
        document.dispatchEvent(new CustomEvent(Events.SHOW_LOADING_PAGE, { detail: { loadingMessage: 'Loading Raven List' } }));



    }

    componentDidMount() {
        // Get all accounts
        this.dataStore.queryAccounts(this.props.stage);
        this.localState.stage = this.props.stage;
        this.createAccountList()

    }

    componentWillReceiveProps(nextProps) {
        this.createAccountList();
    }

    componentDidUpdate() {

        var cb = new Clipboard('#copyPasswdBtn');

        if(this.props.stage !== this.localState.stage)
        {
            this.localState.stage = this.props.stage;
            this.dataStore.queryAccounts(this.props.stage);
        }


    }

    createAccountList() {
        this.accountList = this.state.ravenData.accounts.map(elem => {
                var obj = elem['account'];
                if(!obj)
                    return null;

                if(obj['channelCode'] !== 'RAVEN' && obj['channelCode'] !== 'TELUS')
                    return null;

                return { value : '' + obj['email'],
                    label: '' + obj['email'] + ' (' + obj['accountExternalId'] + ')',
                    id: obj['accountId'],
                    obj: obj,
                    isDisabled: obj['type'] !== 'FLEET',
                    disabled: obj['type'] !== 'FLEET'
                };

            });
    }

    dataChangeCallback = (ravenData) => {
        document.dispatchEvent(new CustomEvent(Events.HIDE_LOADING_PAGE));
        this.setState({ravenData: ravenData});
    }


    openModal = (e) => {
        this.setState({isOpen: true});
    }

    closeModal = (e) => {
        this.setState({isOpen: false});
    }

    onSelectAccountSearch = (id) => {

        var entry = this.state.ravenData.accounts.find((elem) => {
            if(!elem.account)
                return false;
            return elem.account.email === id.value;
        });

        if(entry)
        {
            this.setState({"account": id, "accountDetails": entry.account});

            this.userList = entry.account.users.map(elem => {
                    var obj = elem;

                    if(!obj)
                        return null;

                    return { value : '' + obj['email'],
                        label: '' + obj['email'] + ' (' + obj['cognito_id'] + ')',
                        id: obj['email'],
                        obj: obj,
                        role: obj['role']
                    };

                });
            }
    }

    onSelectUserSearch = (id) => {

        var entry = this.state.accountDetails.users.find((elem) => {
            return elem.email === id.value;
        });

        if(entry)
        {

            var role_name = entry.role;
            var role = { value : '1', label: 'VIEWER' };
            if(role_name == 'ADMIN') {
                role = { value : '0', label: 'ADMIN' };
            }

            this.setState({"user": id, roleid: role});
        }
    }

    onRoleChange = (val) => {
        this.setState({roleid: val });
    }

    reportErrorToUser = (err) => {

        console.error(err);
        document.dispatchEvent(new CustomEvent(Events.HIDE_LOADING_PAGE));
        var message = err && err.message ? err.message : "An unknown error occured while trying to update user. Please contact support to resolve this issue."; //TODO LOC BILLING
        this.setState({ message: message, messageType: 'error' });
    }


    onUpdateUser = (e) => {
        e.preventDefault();
        var form = e.target;

        if(!this.state.account || !this.state.account.value)
        {
            return this.reportErrorToUser({message: "No Account Selected", messageType: 'error'}); 
        }

        if(!this.state.user)
        {
            return this.reportErrorToUser({message: "Email not set", messageType: 'error'}); 
        }
        if(!this.state.roleid)
        {
            return this.reportErrorToUser({message: "Role not set", messageType: 'error'}); 
        }

        else
        {
            this.performUserUpdate(null);
        }
    }

    performUserUpdate = (recurlyToken) => {
    
        document.dispatchEvent(new CustomEvent(Events.SHOW_LOADING_PAGE,
                { detail: { loadingMessage: 'Update Fleet User For Account' } }));


        /* FIXME - add token to URL*/
        console.log("PERFORM USER UPDATE", this.props.stage, 
                this.state.account.value.toLowerCase(), this.state.user.obj.email.toLowerCase(), 
                this.state.roleid.label);

        this.props.dataStore.updateFleetUser(this.props.stage, 
                this.state.account.value.toLowerCase(), this.state.user.obj.email.toLowerCase(), 
                this.state.roleid.label, 
                (json) => {
                // On Success
              document.dispatchEvent(new CustomEvent(Events.HIDE_LOADING_PAGE));

              if(!json.payload)
                {
                  document.dispatchEvent(new CustomEvent(Events.HIDE_LOADING_PAGE));
                  this.setState({message: "Error: " + json.errorMessage ? json.errorMessage : "Unknown" , messageType: 'error'});
                  console.error("User Update Error", json);
                  return;
                }

              this.setState({
                    updated: true, 
                    message: json.status,
                    messageType: 'success'
                });

            this.dataStore.queryAccounts(this.props.stage);
            
        }, (error) => {
                // On Fail
              document.dispatchEvent(new CustomEvent(Events.HIDE_LOADING_PAGE));
              this.setState({message: "Error: " + error, messageType: 'error'});
              console.error("User Update Error", error);
        });

    }

    doNothing = (e) => {
        e.preventDefault();
    }

    render() {

        // FIXME - probably not the most efficient.
        this.roles = [ { value : '0', label: 'ADMIN' },
                    { value : '1', label: 'VIEWER' },
                    { value : '-1', label: 'REMOVE' },
                ];

        var errorMessage = null;

        if(this.state.messageType === 'success')
        {
            errorMessage = this.state.message ?
                <p className="success-message"><i className="material-icons">check_circle_outline</i>{this.state.message}</p> :
                null;
        }
        else
        {
            errorMessage = this.state.message ?
                <p className="error-message"><i className="material-icons">error_outline</i>{this.state.message}</p> :
                null;
        }

        return (
            <Dialog
                size="medium"
                className="test"
                label={this.props.title}
                isOpen={this.props.isOpen}
                onClose={this.props.onClose}
                onAfterOpen={this.reinitializeRecurly}
                >

                <div className="container-fluid form-card">

                    <div className="row">

                        <div className="col-12 content-group">

                            <h2>User Details</h2>

                            <div className="row">
                                <div className="col-2">
                                    <label htmlFor="stage">Stage</label>
                                </div>
                                <div className="col-4">{this.props.stage} </div>
                                <div className="col-2">
                                    <label htmlFor="role">Role</label>
                                </div>
                                <div className="col-2">
                                    <Select id="role"
                                    placeholder="Role..."
                                    searchable={true}
                                    value={this.state.roleid}
                                    options={this.roles}
                                    name="role"
                                    onChange={this.onRoleChange}
                                    clearable={false} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-2">
                                    <label htmlFor="account">Existing Account</label>
                                </div>
                                <div className="col-10" >
                                    <Select id="accountSearch"
                                        placeholder="Search..."
                                        searchable={true}
                                        options={this.accountList}
                                        value={this.state.account}
                                        name="accountSearch"
                                        filterOption={(option, filter) => {
                                            if(!option)
                                                return false;
                                            // Search: ravenid, raven unit id, email, raven enclosure id 
                                            else if(option.obj['accountId'] && option.obj['accountId'].toString().includes(filter))
                                                return true;
                                            else if(option.obj['email'] && option.obj['email'].toString().includes(filter))
                                                return true;
                                            return false;
                                        }}
                                        onChange={this.onSelectAccountSearch}
                                        clearable={false} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-2">
                                    <label htmlFor="email">User</label>
                                </div>
                                <div className="col-10" >
                                    <Select id="userSearch"
                                        placeholder="Search..."
                                        searchable={true}
                                        options={this.userList}
                                        value={this.state.user}
                                        name="userSearch"
                                        onChange={this.onSelectUserSearch}
                                        clearable={false} />
                                </div>
                            </div>

                            <form className="row" onSubmit={this.onUpdateUser}>

                                <div className="col-12">
                                    {this.state.user && this.state.roleid ?
                                        <button>Update User</button> :
                                        <button disabled>Update User</button>
                                    }
                                    {this.state.updated ?
                                        <div>
                                            Updated
                                            <Link to={"/account/" + this.props.stage + "/" + this.state.email.toLowerCase()}>Go to Account</Link> 
                                        </div> :
                                        null
                                    }
                                    {errorMessage}
                                </div>
                            </form>


                        </div>
                    </div>
                    
                    </div>
            </Dialog>
        );
    }

}

export default class UpdateUser extends Component {

    constructor() {
        super();

        this.state = {
            modalIsOpen: false,
        }
    }

    openModal = (e) => {
        e.preventDefault();

        this.setState({modalIsOpen: true});
    }

    closeModal = (e) => {
        this.setState({modalIsOpen: false});
    }

    render()
    {
        var title = "Update Fleet User (" + this.props.stage + ")"; 


        return ( 
            <div className="3rdparty d-inline-block">
                <a href="#!" onClick={this.openModal}>
                    <img src="/images/baseline_person_add_white_24dp.png" alt="Update Fleet User"/>
                </a>
                <UpdateUserModal title={title} isOpen={this.state.modalIsOpen} 
                        stage={this.props.stage}
                        onClose={() => {this.setState({modalIsOpen: false})}} 
                />
            </div>
        );

    }
}

