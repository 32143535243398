import React from 'react';
import PropTypes from 'prop-types';
import globalconfig from '../../../common/config';
import { ACCOUNT_INTEGRATIONS_TYPES } from '../../orders/OrderForm';
import { ORDER_TYPES } from '../../orders/OrderForm';
import supporttoolIconInfoWhite from './../panels/Diagnostics/images/supporttool-icon-info-white.png'
import ProgressOverlay from '../../../common/ProgressOverlay';
import RavenDataStore from '../../../common/ravenDataStore';
import { ConfirmationDialog } from '../../../modals/Modal2';

const PREEXISTING_INTEGRATION_IMPORT_TYPE_DEFAULT = "NO_CHANGE";

export default class AccountGeotabIntegrationDetails extends React.PureComponent {

    static propTypes = {
        stage: PropTypes.string.isRequired,
        accountId: PropTypes.string.isRequired,
        onDismiss: PropTypes.func.isRequired
    };

    constructor(props) {

        super(props);

        this.state = {

            accountIntegrationsSectionPending: true,
            accountIntegrationDetailsSectionPending: true,
            accountIntegrationsSectionTitle: "Geotab Integration",
            showAccountIntegrationsUpdateButton: true,
            accountIntegrationsUpdateButtonTitle: "Enable Geotab Integration",
            accountIntegrationTypeErrorMessage: undefined,
            accountIntegrationDetailsBulkImportTypeErrorMesage: undefined,
            accountIntegrationDetailsDigDevicesErrorMesage: undefined,
            accountIntegrationDetailsDatabaseNameErrorMesage: undefined,

            accountGeotabIntegrationFromCloud: undefined,
            accountGeotabIntegrationDevicesFromCloud: undefined,
            accountGeotabIntegrationsDevicesCommonMode: undefined,
            accountGeotabIntegrationsTotalPairedDevices: 0,

            // local edits
            accountDevicesSerialNumbersFromMiddleman: undefined, // populated when account does not have a geotab integration
            accountIntegrationType: undefined,
            accountBulkGeotabRavenImportType: undefined,
            geotabDatabaseName: undefined,
            // if local edits must be completed
            validateGeotabOnDismiss: false

        };

        this.ravenDataStore = new RavenDataStore(() => {});

        this.accountExternalIdTitle = "Account Number";
        if (globalconfig.features &&
            globalconfig.features.accounts &&
            globalconfig.features.accounts.externalIdOptionalTitle) {

            this.accountExternalIdTitle = globalconfig.features.accounts.externalIdOptionalTitle;
        }

    }

    componentDidMount() {
        this.getAllAccountGeotabIntegrationDetails();
    }

    getAllAccountGeotabIntegrationDetails = () => {

        const allRequestsCompleted = () => { // intentional alternative to Promise.all; more specific error handling
            this.setState({
                accountIntegrationsSectionPending: false,
                accountIntegrationDetailsSectionPending: false
            });
        };

        this.getAccountGeotabIntegration()
        .then(
            (geotabIntegrationResponse) => {
                this.getAccountGeotabDigDevices()
                .then(
                    (geotabDigDevicesResponse) => {
                        if (geotabDigDevicesResponse.results && geotabDigDevicesResponse.results.length > 0) {
                            allRequestsCompleted();
                            return;
                        }
                        // no dig devices found, this could be because of no integration or an incomplete integration
                        // get all ravens for account
                        this.setState({
                            accountIntegrationDetailsSectionPending: true
                        });
                        this.ravenDataStore.getAllRavensForAccount(this.props.stage, this.props.accountId)
                        .then(
                            (ravensForAccount) => {

                                /*
                                    [{"Unit Id":2410200350,
                                        "State":"Parked",
                                        "ICCID":"8912230100127378642",
                                        "Raven Status":"PROVISIONED",
                                        "Last Provisioned Date":1712535536,
                                        "Serial No.":"K06240058",
                                        "Enclosure Serial No.":"2RVP102400350", <-- this is what we want
                                        "Raven Unit Status":"NEW",
                                        "Fan Type":"New fan",
                                        "Raven UUID":"af504e3f6b8d4ab98d3b9169a2d369e7",
                                        "Owner Name":"",
                                        "IMEI":"015585000384381",
                                        "Vendor Code":null,
                                        "Raven External Id":"2042490032"},
                                        ...  
                                    ]
                                */
                                const devicesSerialNumbersFromMiddleman = [];
                                ravensForAccount.forEach( (rawRavenObject) => {
                                    if (!rawRavenObject["Enclosure Serial No."]) {
                                        console.warn("AccountGeotabIntegrationDetails getAllAccountGeotabIntegrationDetails getAllRavensForAccount rawRavenObject missing 'Serial No.' property", rawRavenObject);
                                        return;
                                    }
                                    devicesSerialNumbersFromMiddleman.push(rawRavenObject["Enclosure Serial No."]);
                                })
                                this.setState({
                                    accountDevicesSerialNumbersFromMiddleman: devicesSerialNumbersFromMiddleman
                                });
                                
                                allRequestsCompleted();
                            },
                            (error) => {
                                this.setState({
                                    accountIntegrationDetailsBulkImportTypeErrorMesage: error ? error.message : "Error getting raven serial numbers for account"
                                });
                                allRequestsCompleted();
                            }
                        );        
                    },
                    (geotabDigDevicesError) => {
                        console.error("AccountGeotabIntegrationDetails getAllAccountGeotabIntegrationDetails geotabDigDevicesError", geotabDigDevicesError);
                        allRequestsCompleted();
                    }
                );
            },
            (geotabIntegrationError) => {
                console.error("AccountGeotabIntegrationDetails getAllAccountGeotabIntegrationDetails geotabIntegrationError", geotabIntegrationError);
                allRequestsCompleted();
            }
        )
    };

    getAccountGeotabIntegration = () => {
        if (!this.props.stage) {
            const error = new Error("AccountGeotabIntegrationDetails getAccountGeotabIntegration missing required prop stage");
            return Promise.reject(error);
        }
        if (!this.props.accountId) {
            const error = new Error("AccountGeotabIntegrationDetails getAccountGeotabIntegration missing required prop accountId");
            return Promise.reject(error);
        }
        return this.ravenDataStore.getAccountGeotabIntegration(this.props.stage, this.props.accountId)
        .then(
            (accountGeotabInegrationResponse) => {

                this.setState({
                    accountGeotabIntegrationFromCloud: accountGeotabInegrationResponse.details, // will be undefined if response is 404 (integration not found)
                });
                return Promise.resolve(accountGeotabInegrationResponse);
            },
            (error) => {
                this.setState({
                    accountGeotabIntegrationFromCloud: undefined,
                    accountIntegrationTypeErrorMessage: error.message ? error.messagee : "An error occurred while loading account geotab integration"
                });
                return Promise.reject(error);
            }
        );
    };

    getAccountGeotabDigDevices = () => {
        if (!this.props.stage) {
            const error = new Error("AccountGeotabIntegrationDetails getAccountGeotabDigDevices missing required prop stage");
            return Promise.reject(error);
        }
        return this.ravenDataStore.getGeotabIntegrationsForAccountRavens(this.props.stage, this.props.accountId)
        .then(
            (accountGeotabDigDevicesResponse) => {
                this.setState({
                    accountGeotabIntegrationDevicesFromCloud: accountGeotabDigDevicesResponse.results && accountGeotabDigDevicesResponse.results.length > 0 ? accountGeotabDigDevicesResponse.results : undefined
                });
                this.updateIntegrationsDevicesCommonModeState();
                return Promise.resolve(accountGeotabDigDevicesResponse);
            },
            (error) => {
                this.setState({
                    accountGeotabIntegrationDevicesFromCloud: undefined,
                    accountIntegrationDetailsDatabaseNameErrorMesage: "Error occurred while loading devices"
                });
                return Promise.reject(error);
            }
        );
    };

    updateIntegrationsDevicesCommonModeState = () => {

        if (!this.state.accountGeotabIntegrationDevicesFromCloud) return undefined;

        let commonMode = undefined;
        let pairedDevicesFound = 0;

        this.state.accountGeotabIntegrationDevicesFromCloud.forEach( (deviceIntegration) => {
            let ravenIntegrationMode = deviceIntegration.geotab ? deviceIntegration.geotab.mode : undefined;
            let deviceMode = undefined;
            switch (ravenIntegrationMode) {
                case "PAIRED":
                    if (deviceIntegration.geotab.pairedAsset) {
                        pairedDevicesFound += 1;
                    }
                    deviceMode = ORDER_TYPES.RAVEN_GT;
                    break;
                case "STANDALONE":
                    deviceMode = ORDER_TYPES.RAVEN_GTS;
                    break;
                default:
                    console.error("AccountGeotabIntegrationDetails digDevicesCommonMode unrecognized deviceIntegration.geotab.mode", deviceIntegration);
                    break;
            }
            if (!commonMode) {
                commonMode = deviceMode;
            } else if (deviceMode === commonMode) {
                return;
            } else {
                commonMode = PREEXISTING_INTEGRATION_IMPORT_TYPE_DEFAULT;
            }
        });

        this.setState({
            accountGeotabIntegrationsDevicesCommonMode: commonMode ? commonMode : PREEXISTING_INTEGRATION_IMPORT_TYPE_DEFAULT,
            accountGeotabIntegrationsTotalPairedDevices: pairedDevicesFound
        });

    }

    onInputChange = (event) => {

        switch (event.target.name) {
            case "accountIntegrationType":
                const accountIntegrationTypeFromCloud = this.state.accountGeotabIntegrationFromCloud ? ACCOUNT_INTEGRATIONS_TYPES.GEOTAB : ACCOUNT_INTEGRATIONS_TYPES.NONE;
                this.setState({
                    accountIntegrationType: event.target.value === accountIntegrationTypeFromCloud ? undefined : event.target.value, // clear value if equal to type already set in the cloud
                    accountIntegrationsUpdateButtonTitle: event.target.value === ACCOUNT_INTEGRATIONS_TYPES.GEOTAB ? "Enable Geotab Integration" : "Remove Geotab Integration",
                    accountIntegrationTypeErrorMessage: undefined
                });
                return;
            case "bulkImportType":
                // this type is applied to all regardless of their type in the cloud
                const requestedMode = event.target.value;
                switch (requestedMode) {
                    case ORDER_TYPES.RAVEN_GTS:
                        if (this.state.accountGeotabIntegrationsTotalPairedDevices) {
                            this.setState({
                                accountIntegrationDetailsBulkImportTypeErrorMesage: this.state.accountGeotabIntegrationsTotalPairedDevices + " Raven(s) on this account are currently paired. All GT Ravens on the account must be unpaired in MyGeotab's 'Manage Ravens' add-in page before changing mode to GTS.",
                                accountBulkGeotabRavenImportType: requestedMode
                            });
                            return;
                        }
                        break;
                    case ORDER_TYPES.RAVEN_GT:
                    case PREEXISTING_INTEGRATION_IMPORT_TYPE_DEFAULT:
                        break;
                    default:
                        console.error("AccountGeotabIntegrationDetails onInputChange invalid target value for bulkImportType", requestedMode);
                        break;
                }
                this.setState({
                    accountIntegrationDetailsBulkImportTypeErrorMesage: undefined,
                    accountBulkGeotabRavenImportType: requestedMode === this.state.accountGeotabIntegrationsDevicesCommonMode ? undefined : requestedMode // clear value if equal to type already set in the cloud
                });
                return;
            case "geotabDatabaseName":
                const geotabDatabaseNameFromCloud = this.state.accountGeotabIntegrationFromCloud ? this.state.accountGeotabIntegrationFromCloud.databaseName : "";
                this.setState({
                    geotabDatabaseName: event.target.value === geotabDatabaseNameFromCloud ? undefined : event.target.value, // clear value if equal to type already set in the cloud
                    accountIntegrationDetailsDatabaseNameErrorMesage: undefined
                });
                return;
    
            default:
                console.error("AccountGeotabIntegrationDetails onInputChange invalid input name " + event.target.name);
                break;
        }


    };

    updateAccountIntegrationsApplyClick = () => {

        const onClose = () => {
            this.setState({
                confirmationDialog: null,
                accountIntegrationType: undefined
            });
        }

        const onApply = () => {

            this.setState({
                accountIntegrationsSectionPending: true,
                accountIntegrationDetailsSectionPending: true
            });    

            const onError = (error) => {
                const errorMessage = error.message ? error.message : "Unknown error occurred";
                this.setState({
                    accountIntegrationsSectionPending: true,
                    accountIntegrationDetailsSectionPending: true,
                    accountIntegrationTypeErrorMessage: errorMessage
                });
            };

            onClose();
            if (this.state.accountIntegrationType === ACCOUNT_INTEGRATIONS_TYPES.GEOTAB) {
                this.ravenDataStore.addAccountGeotabIntegration(this.props.stage, this.props.accountId)
                .then(
                    () => {
                        this.setState({
                            accountIntegrationsSectionPending: true,
                            accountIntegrationDetailsSectionPending: true,
                            validateGeotabOnDismiss: true // geotab integration has been added but it needs to be completed (database name etc)
                        })
                        // refresh / sync with cloud
                        this.getAllAccountGeotabIntegrationDetails();
                    },
                    (error) => {onError(error)}
                );
            } else {

                this.ravenDataStore.deleteAccountGeotabIntegration(this.props.stage, this.props.accountId, )
                .then(
                    () => {
                        this.setState({
                            accountIntegrationsSectionPending: true,
                            accountIntegrationDetailsSectionPending: true
                        })
                        // refresh / sync with cloud
                        this.getAllAccountGeotabIntegrationDetails();
                    },
                    (error) => {onError(error)}
                );
            }
        };

        let title = "Please Confirm";

        let message = "This update will add Geotab integration for this " + this.accountExternalIdTitle + ". This change will affect all ravens on this account. Are you sure you want to add Geotab integration for this account?";
        let cancelButtonTitle = "No, cancel this change";
        let submitButtonTitle = "Yes, add Geotab integration";
        if (this.state.accountIntegrationType === ACCOUNT_INTEGRATIONS_TYPES.NONE) {
            message = "This update will remove Geotab integration for this " + this.accountExternalIdTitle + ". This change will affect all ravens on this account.  Are you sure you want to remove Geotab integration for this account?";
            submitButtonTitle = "Yes, remove Geotab integration";
        }

        this.setState({
            confirmationDialog: <ConfirmationDialog
                title={title}
                message={message}
                cancelButtonAction={onClose}
                cancelButtonTitle={cancelButtonTitle}
                hideHeaderCloseButton={true}
                submitButtonAction={onApply}
                submitButtonTitle={submitButtonTitle}
            />
        });

    };

    updateGeotabDigDevicesDefaultType = (event) => {

        const accountBulkGeotabRavenImportType = this.state.accountBulkGeotabRavenImportType;

        if (!accountBulkGeotabRavenImportType || accountBulkGeotabRavenImportType == PREEXISTING_INTEGRATION_IMPORT_TYPE_DEFAULT) {
            this.setState({
                accountIntegrationDetailsBulkImportTypeErrorMesage: "Invalid bulk raven mode type, " + accountBulkGeotabRavenImportType + ". Please contact support."
            });
            return;
        }

        let mode = accountBulkGeotabRavenImportType === ORDER_TYPES.RAVEN_GT ? "PAIRED" : "STANDALONE";
        
        if (mode === "PAIRED") {
            if (this.state.accountGeotabIntegrationsTotalPairedDevices) {
                this.setState({
                    accountIntegrationDetailsBulkImportTypeErrorMesage: this.state.accountGeotabIntegrationsTotalPairedDevices + " Raven(s) on this account are currently paired. All GT Ravens on the account must be unpaired in MyGeotab's 'Manage Ravens' add-in page before changing mode to GTS.",
                });
                return;
            }
        }

        /*if (!this.state.accountGeotabIntegrationDevicesFromCloud) {
            this.setState({
                accountIntegrationDetailsBulkImportTypeErrorMesage: "Integration devices not found. Please reload and try again."
            });
            return;
        }*/

        let ravenSNs = [];

        // pre-existing geotab integration?
        if (this.state.accountGeotabIntegrationDevicesFromCloud) {
            this.state.accountGeotabIntegrationDevicesFromCloud.forEach( (deviceIntegration) => {
                if (deviceIntegration.ravenSerial) {
                    ravenSNs.push(deviceIntegration.ravenSerial);
                }
            });
        // new geotab integration?
        } else if (this.state.accountDevicesSerialNumbersFromMiddleman) {
            ravenSNs = this.state.accountDevicesSerialNumbersFromMiddleman;
        }

        if (!ravenSNs) {
            this.setState({
                accountIntegrationDetailsBulkImportTypeErrorMesage: "Integration devices not found. Please reload and try again."
            });
            return;
        }

        if (ravenSNs.length === 0) {
            this.setState({
                accountIntegrationDetailsBulkImportTypeErrorMesage: "Integration devices not found. Please reload and try again."
            });
            return;
        }

        this.setState({
            accountIntegrationDetailsSectionPending: true
        });

        this.ravenDataStore.setRavenGeotabIntegrationMode(this.props.stage, this.props.accountId, ravenSNs, mode)
        .then(
            (response) => {
                this.getAccountGeotabDigDevices()
                .then(
                    () => {
                        this.setState({
                            accountIntegrationDetailsSectionPending: false,
                            accountBulkGeotabRavenImportType: undefined
                        });
                    },
                    () => {}
                );
            },
            (error) => {
                this.setState({
                    accountIntegrationDetailsSectionPending: false,
                    accountIntegrationDetailsBulkImportTypeErrorMesage: error.message
                });    
            }
        );

    };

    accountIntegrationsLocalEditsFound = () => {
        if (this.state.accountIntegrationType) { // Assumption: onInputChange for radio buttons is only triggered by actually changing the selection (therefore state is only used when the change is pending, and cleared on apply)
            return true;
        }
        /*
        if (this.state.geotabDatabaseName) {
            return true
        }*/
        return false;
    };

    updateAccountGeotabIntegrationDatabaseName = (geotabDatabaseName) => {

        if (!geotabDatabaseName) {
            console.error("AccountGeotabIntegrationDetails updateAccountGeotabIntegrationDatabaseName geotabDatabaseName missing");
            return;
        }

        return this.ravenDataStore.addAccountGeotabIntegration(this.props.stage, this.props.accountId, geotabDatabaseName)
        .then(
            (addAccountGeotabIntegrationResponse) => {

                this.getAllAccountGeotabIntegrationDetails(); // sync details to update props
                return Promise.resolve(addAccountGeotabIntegrationResponse);
            },
            (error) => {
                console.error(error);
                return Promise.reject(error);
            }
        );
    };

    updateAccountGeotabIntegrationDatabaseNameSaveClick = () => {

        let geotabDatabaseName = this.state.geotabDatabaseName;

        this.setState({accountIntegrationDetailsSectionPending: true});

        this.updateAccountGeotabIntegrationDatabaseName(geotabDatabaseName)
        .then(
            () => {
                this.getAccountGeotabIntegration()
                .then(
                    (accountGeotabIntegrationResponse) => {
                        this.setState({
                            accountIntegrationDetailsSectionPending: false,
                            geotabDatabaseName: undefined // clear local state to hide "Save" button
                        });
                    },
                    (error) => {
                        this.setState({
                            accountIntegrationDetailsSectionPending: false,
                            accountIntegrationDetailsDatabaseNameErrorMesage: error.message
                        });
                        console.log(error);        
                    }
                );
            },
            (error) => {
                this.setState({
                    accountIntegrationDetailsSectionPending: false,
                    accountIntegrationDetailsDatabaseNameErrorMesage: error.message
                });
                console.log(error);
            }
        )

    };

    onClickDownloadGeotabDeviceSerialNumbers = (event) => {

        // supports redundant request to set as same value as in cloud (see onClickDownloadGeotabDeviceSerialNumbers)
        let geotabDatabaseName = this.state.geotabDatabaseName ? this.state.geotabDatabaseName : this.state.accountGeotabIntegrationFromCloud ? this.state.accountGeotabIntegrationFromCloud.databaseName : undefined;

        if (!geotabDatabaseName) {
            console.error("AccountGeotabIntegrationDetails onClickDownloadGeotabDeviceSerialNumbers geotabDatabaseName missing");
            return;
        }

        this.setState({accountIntegrationDetailsSectionPending: true});

        this.updateAccountGeotabIntegrationDatabaseName(geotabDatabaseName) // supports redundant requests to re-apply the same database name
        .then(
            (response) => {
                this.setState({accountIntegrationDetailsSectionPending: false});
                const responseIntegrationDetailsDatabaseName = response && response.details ? response.details.databaseName : undefined;
                if (!responseIntegrationDetailsDatabaseName) {
                    console.error("AccountGeotabIntegrationDetails onClickDownloadGeotabDeviceSerialNumbers checkAndSaveGeotabIntegration response geotab integration failed", response);
                } else {
                    this.getGeotabDevicesCSVAndInitiateDownload(geotabDatabaseName);
                }
            },
            (error) => {
                this.setState({
                    accountIntegrationDetailsSectionPending: false,
                    accountIntegrationDetailsDatabaseNameErrorMesage: error.message
                });
                console.log(error);
            }
        )
    }


    getGeotabDevicesCSVAndInitiateDownload = () => {

        const accountExternalId = this.props.accountId;

        if (!accountExternalId) {
            console.error("AccountGeotabIntegrationDetails getGeotabDevicesCSVAndInitiateDownload accountExternalId not found in props.");
            return;
        }

        this.setState({accountIntegrationDetailsSectionPending: true});

        this.ravenDataStore.getAccountGeotabDigDevices(this.props.stage, accountExternalId, true)
        .then(
            (response) => {
                this.setState({accountIntegrationDetailsSectionPending: false});
                const dataUri = "data:text/csv;charset=utf-8," + response;
                window.open(dataUri);   
            },
            (error) => {
                this.setState({accountIntegrationDetailsSectionPending: false});
                console.error("AccountGeotabIntegrationDetails getGeotabDevicesCSVAndInitiateDownload getAccountGeotabDigDevices error", error);
            }
        );
    };

    render() {

        let accountIntegrationsSelectionDescription = "Customer will use TELUS | Raven Web App";

        let accountIntegrationType = this.state.accountIntegrationType ? this.state.accountIntegrationType : this.state.accountGeotabIntegrationFromCloud ? ACCOUNT_INTEGRATIONS_TYPES.GEOTAB : ACCOUNT_INTEGRATIONS_TYPES.NONE;
        if (accountIntegrationType === ACCOUNT_INTEGRATIONS_TYPES.GEOTAB) {
            accountIntegrationsSelectionDescription = "Customer will have access to MyGeotab Portal";
        }

        let geotabDigDevicesFound = false;
        if (this.state.accountGeotabIntegrationDevicesFromCloud) {
            geotabDigDevicesFound = true;
        }

        let accountBulkGeotabRavenImportType = this.state.accountBulkGeotabRavenImportType ? this.state.accountBulkGeotabRavenImportType : this.state.accountGeotabIntegrationsDevicesCommonMode;

        let geotabDatabaseName = this.state.geotabDatabaseName !== undefined ? this.state.geotabDatabaseName : this.state.accountGeotabIntegrationFromCloud ? this.state.accountGeotabIntegrationFromCloud.databaseName : "";

        const accountIntegrationTypeEditInProgress = this.accountIntegrationsLocalEditsFound();

        let showNoChangeOptionForBulkImport = false;
        if (geotabDigDevicesFound) {
            switch (this.state.accountGeotabIntegrationsDevicesCommonMode) {
                case ORDER_TYPES.RAVEN_GT: // all ravens are one mode/type -> GT
                case ORDER_TYPES.RAVEN_GTS: // all ravens are one mode/type -> GTS
                    showNoChangeOptionForBulkImport = false; // no need to "no change" if all ravens are a certain type
                    break;
                case PREEXISTING_INTEGRATION_IMPORT_TYPE_DEFAULT: // default is to not change the ravens ("NO_CHANGE")
                    showNoChangeOptionForBulkImport = true;
                    break;
                default:
                    console.error("AccountGeotabIntegrationDetails render unrecognized accountGeotabIntegrationsDevicesCommonMode while geotabDigDevicesFound");
                    showNoChangeOptionForBulkImport = true; // try to fail gracefully
                    break;
            }
        }

        let geotabConfigurationReadyForCSVExport = true;
        if (accountIntegrationTypeEditInProgress) {
            geotabConfigurationReadyForCSVExport = false;
        }
        if (this.state.accountBulkGeotabRavenImportType && this.state.accountBulkGeotabRavenImportType !== PREEXISTING_INTEGRATION_IMPORT_TYPE_DEFAULT) {
            geotabConfigurationReadyForCSVExport = false;
        }
        if (this.state.geotabDatabaseName || !geotabDatabaseName) {
            geotabConfigurationReadyForCSVExport = false;
        }

        return (
            <>
            <div className="order-form request-to-ship modal-geotab-integration">
                <header>
                    {this.state.accountIntegrationsSectionTitle}
                </header>
                <section className={"account-integrations"}>
                    <div>
                        <div className="order-form-radio-wrapper">
                            <input autoComplete="off" type="radio" name="accountIntegrationType" checked={accountIntegrationType === ACCOUNT_INTEGRATIONS_TYPES.GEOTAB} value={ACCOUNT_INTEGRATIONS_TYPES.GEOTAB} id={ACCOUNT_INTEGRATIONS_TYPES.GEOTAB} onChange={this.onInputChange} />
                            <label htmlFor={ACCOUNT_INTEGRATIONS_TYPES.GEOTAB}>Yes</label>
                        </div>

                        <div className="order-form-radio-wrapper">
                            <input autoComplete="off" type="radio" name="accountIntegrationType" checked={accountIntegrationType === ACCOUNT_INTEGRATIONS_TYPES.NONE} value={ACCOUNT_INTEGRATIONS_TYPES.NONE} id={ACCOUNT_INTEGRATIONS_TYPES.NONE} onChange={this.onInputChange} />
                            <label htmlFor={ACCOUNT_INTEGRATIONS_TYPES.NONE}>No</label>
                        </div>

                        <span className="required-badge">•</span>{accountIntegrationsSelectionDescription}
                        {/*<span className="material-icons">search</span>*/}
                    </div>
                    <div className="error-message">{this.state.accountIntegrationTypeErrorMessage}</div>
                    {accountIntegrationTypeEditInProgress ?
                        <div className="actions">
                            <button onClick={this.updateAccountIntegrationsApplyClick} >
                                {this.state.accountIntegrationsUpdateButtonTitle}
                            </button>
                        </div>
                    :
                        null
                    }
                    {this.state.accountIntegrationsSectionPending ? <ProgressOverlay /> : null }
                </section>
                <header>
                    Complete The Geotab Configuration:
                </header>
                <section>
                    <div style={{position: "relative"}}>
                        <label>{"Bulk Geotab Import Raven Type"}<span className="required-badge">•</span></label>
                        {showNoChangeOptionForBulkImport ?
                            <div className="order-form-radio-wrapper">
                                <input autoComplete="off" type="radio" name="bulkImportType" checked={accountBulkGeotabRavenImportType === PREEXISTING_INTEGRATION_IMPORT_TYPE_DEFAULT} value={PREEXISTING_INTEGRATION_IMPORT_TYPE_DEFAULT} id={PREEXISTING_INTEGRATION_IMPORT_TYPE_DEFAULT} onChange={this.onInputChange} />
                                <label htmlFor={PREEXISTING_INTEGRATION_IMPORT_TYPE_DEFAULT}>No Change</label>
                            </div>
                        :
                            null
                        }
                        <div className="order-form-radio-wrapper">
                            <input autoComplete="off" type="radio" name="bulkImportType" checked={accountBulkGeotabRavenImportType === ORDER_TYPES.RAVEN_GT} value={ORDER_TYPES.RAVEN_GT} id={ORDER_TYPES.RAVEN_GT} onChange={this.onInputChange} />
                            <label htmlFor={ORDER_TYPES.RAVEN_GT}>GT</label>
                        </div>
                        <div className="order-form-radio-wrapper">
                            <input autoComplete="off" type="radio" name="bulkImportType" checked={accountBulkGeotabRavenImportType === ORDER_TYPES.RAVEN_GTS} value={ORDER_TYPES.RAVEN_GTS} id={ORDER_TYPES.RAVEN_GTS} onChange={this.onInputChange} />
                            <label htmlFor={ORDER_TYPES.RAVEN_GTS}>GTS</label>
                        </div>
                        <div className="diagnostics">
                            <div className="info-icon">
                                <img src={supporttoolIconInfoWhite} />
                                <div className="tooltip-large">
                                    <span>
                                        {"Please select the type of ravens for this Geotab order. GT ravens are paired with Go9s. GTS ravens are stand-alone."}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="error-message">{this.state.accountIntegrationDetailsBulkImportTypeErrorMesage}</div>
                    {this.state.accountBulkGeotabRavenImportType && this.state.accountBulkGeotabRavenImportType !== PREEXISTING_INTEGRATION_IMPORT_TYPE_DEFAULT ?
                        <div className="actions">
                            <button disabled={this.state.accountGeotabIntegrationsTotalPairedDevices && this.state.accountBulkGeotabRavenImportType === ORDER_TYPES.RAVEN_GTS} onClick={this.updateGeotabDigDevicesDefaultType} >
                                Apply
                            </button>
                        </div>
                    :
                        null
                    }
                    <div>
                        <label>{"Step 1 - Geotab Database"}<span className="required-badge">•</span></label>
                        <input type="text" value={geotabDatabaseName || ''} name="geotabDatabaseName" onChange={this.onInputChange} placeholder={"Enter Customer Geotab DB Name"} />
                        <div className="diagnostics">{/* using a diagnostics class wrapper to leverage Diagnostics panel CSS */}
                            <div className="info-icon">
                                <img src={supporttoolIconInfoWhite} />
                                <div className="tooltip-large">
                                    <span>
                                        {"Please insert Customer's Geotab Database name from MyAdmin > Device Admin > Select TELU01 > Look up Devices > Owned Databases"}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="error-message">{this.state.accountIntegrationDetailsDatabaseNameErrorMesage}</div>
                    {this.state.geotabDatabaseName ?
                        <div className="actions">
                            <button onClick={this.updateAccountGeotabIntegrationDatabaseNameSaveClick} >
                                Save
                            </button>
                        </div>
                    :
                        null
                    }
                    <div>
                        <label>{"Step 2 - Export Serial Numbers"}<span className="required-badge">•</span></label>
                        <button disabled={!geotabConfigurationReadyForCSVExport} onClick={this.onClickDownloadGeotabDeviceSerialNumbers}>Download Geotab Serial Numbers</button>
                        <div className="diagnostics">
                            <div className="info-icon">
                                <img src={supporttoolIconInfoWhite} />
                                <div className="tooltip-large">
                                    <span>
                                        Click to download a CSV of the Raven Geotab serial numbers to import for the customer.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <label>{"Step 3 "}<span className="required-badge">{"Reminder"}</span>{" - Add Geotab S/Ns to Customer's Account"}</label>
                        <div className="diagnostics">
                            <div className="info-icon">
                                <img src={supporttoolIconInfoWhite} />
                                <div className="tooltip-large">
                                    <span>
                                        Add using the <a href="https://docs.google.com/document/d/1YBiHzwO4NrqekWvFriBey5g-ayW3-lb1-O9YvIRQcD4/edit" target="_blank">Llama tool</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.accountIntegrationDetailsSectionPending ? <ProgressOverlay /> : null}
                    <div className="error-message">{this.state.accountIntegrationDetailsDigDevicesErrorMesage}</div>{/* onSubmit handles both "Request To Ship" and "Ship" ops; "Request To Ship" leverages the same trackingNumberErrorMessage plumbing as "Ship" */}
                    {accountIntegrationType === ACCOUNT_INTEGRATIONS_TYPES.GEOTAB && accountIntegrationTypeEditInProgress === false ?
                        null
                        :
                        <div className="action-disabled-overlay"></div>
                    }
                    
                </section>
                <div className="actions">
                    <button onClick={this.props.onDismiss} >Close</button>
                </div>
            </div>
            {this.state.confirmationDialog}
            </>
        );
    };
}
