import React, { Component } from 'react';
import Select from 'react-select';

import Dialog from './dialog';

import CognitoUtil from '../../../aws/cognito/cognitoUtil';

/* Class to ask the user to perform OTA and to display the result from das Kloud
*/
export class EditAccountModal extends Component {
    constructor (props) {
        super(props);

        this.state = {
            name: null,
            val: null,
        };
    }

    componentDidMount() {

        if(this.props.property === "notes" || this.props.property === "note")
        {
            this.setState({
                name: "Notes",
                val: this.props.data.account.notes
            });
        }
        else if(this.props.property === "plancode")
        {
            this.setState({
                name: "Plan",
                val: this.props.data.plan.name
            });
        }
        else if(this.props.property === "planprice")
        {
            this.setState({
                name: "Plan Price",
                val: this.props.data.plan.price
            });
        }
        else if(this.props.property === "email")
        {
            this.setState({
                name: "Email",
                val: this.props.data.plan.email
            });
        }

    }

    onValChange = (val) => {
        this.setState({val: val});
    }

    onEditClicked = () => {

        console.log("Sending",this.state);

        // FIXME - actually add the order to the Kloud

        this.props.onSuccess(this.props.property, this.state.val);

        this.props.onClose();
    }

    render() {

        var title = "Edit Account";
        var buttonText = "Edit";

        if(this.props.data && this.props.data.account && this.props.data.account.email)
        {
            title += " for ";
            title += this.props.data.account.email;
        }

        return (
            <Dialog
                size="small"
                label={title}
                isOpen={this.props.isOpen}
                onClose={this.props.onClose}
                >

                <div className="row">
                    <div className="col-3">
                        {this.state.name}
                    </div>
                    <div className="col-9">
                        <input name="orderno" 
                            type="text" 
                            onChange={(e) => { this.onValChange(e.target.value) }}
                            value={this.state.val}
                        />
                    </div>
                    <div className="col-12">
                        <div align="right">
                            <button onClick={this.props.onClose}>Cancel</button> 
                            <button onClick={this.onEditClicked}>{buttonText}</button>
                        </div>
                    </div>

                </div>


            </Dialog>
        );
    }

}
